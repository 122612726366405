<template>
  <header class="bg-white sticky top-0 z-30">
    <nav class="mx-auto flex max-w-7xl items-center justify-between px-4 py-2.5 md:py-5 lg:px-8 lg:py-6"
      aria-label="Global">
      <div class="flex flex-1">
        <div class="hidden lg:flex lg:gap-x-[15px]">
          <a v-for="item in navigation" :key="item.name" :href="item.href"
            class="relative text-xs font-bold leading-8 tracking-[1px] text-gray-900 uppercase before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-full before:h-0.5 before:bg-gray-900 before:opacity-1 before:scale-0 before:origin-center before:transition-opacity before:transition-transform before:hover:scale-100">{{
              item.name }}</a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <img :src="this.hamburgerIcon" class="h-5 w-5" alt="Hamburger Icon" area-hidden="true">
          </button>
        </div>
      </div>
      <a href="https://www.leanhydration.com/" class="-m-1.5 p-1.5">
        <span class="sr-only">LEAN Hydration&trade;</span>
        <img :src="this.logo" class="h-14 w-auto md:h-[74px]" alt="LEAN Hydration">
      </a>
      <div class="flex flex-1 justify-end">
        <a href="https://leanhydration.com/cart" class="text-sm font-semibold leading-6 text-gray-900"><span
            class="sr-only">Cart</span> <img :src="this.shoppingBagIcon" class="h-5 w-5" alt="Shopping Bag"></a>
      </div>
    </nav>
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="bg-[rgba(11,11,11,.4)] opacity-50 fixed inset-0 z-10" />
      <DialogPanel class="fixed inset-y-0 left-0 z-10 w-[90%] max-w-[460px] overflow-y-auto bg-white px-6 py-6">
        <div class="flex items-center justify-between">
          <div class="flex flex-1">
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div class="flex flex-1 justify-end">
            <img :src="this.loginIcon" class="h-4 w-4 mr-1.5" alt="Login Icon">
            <a href="https://leanhydration.com/account/login" class="text-xs text-gray-900">Log in</a>
          </div>
        </div>
        <div class="mt-6 space-y-2">
          <a v-for="item in navigation" :key="item.name" :href="item.href"
            class="-mx-3 border-b border-[rgba(33,33,33,.1)] block rounded-lg px-3 py-5 text-sm text-gray-900 last:border-0 hover:bg-gray-50">{{
              item.name }}</a>
        </div>
        <div>
          <a class="block leading-8 text-gray-900 text-xs w-full" href="mailto:customerservice@leanhydration.com"><img
              :src="this.atIcon" class="h-4 w-4 mr-1.5 inline" alt="At Icon">customerservice@leanhydration.com</a>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'

import Logo from '../../images/lookup/horizontal-logo-color.png'
import AtIcon from '../../images/nav-icons/at-icon.svg'
import HamburgerIcon from '../../images/nav-icons/hamburger-icon.svg'
import LoginIcon from '../../images/nav-icons/login-icon.svg'
import PhoneIcon from '../../images/nav-icons/phone-icon.svg'
import SearchIcon from '../../images/nav-icons/search-icon.svg'
import ShoppingBagIcon from '../../images/nav-icons/shopping-bag-icon.svg'

const navigationConst = [
  { name: 'Shop', href: 'https://leanhydration.com/products/lean-hydration%E2%84%A2-electrolyte-drink-mix-16-servings#' },
  { name: 'About', href: 'https://leanhydration.com/pages/who-we-are' },
  { name: '#LEANnation', href: 'https://leanhydration.com/pages/lean-community' },
  { name: 'ElectroLEAN', href: 'https://leanhydration.com/pages/electrolean%E2%84%A2' },
  { name: 'Learn', href: 'https://leanhydration.com/pages/learn' },
]

const mobileMenuOpen = ref(false)

export default {
  name: "Lookup Header",
  data() {
    return {
      mobileMenuOpen,
      logo: '',
      atIcon: '',
      hamburgerIcon: '',
      loginIcon: '',
      phoneIcon: '',
      searchIcon: '',
      shoppingBagIcon: '',
      navigation: []
    }
  },
  created() {
    this.mobileMenuOpen = mobileMenuOpen
    this.logo = Logo
    this.atIcon = AtIcon
    this.hamburgerIcon = HamburgerIcon
    this.loginIcon = LoginIcon
    this.phoneIcon = PhoneIcon
    this.searchIcon = SearchIcon
    this.shoppingBagIcon = ShoppingBagIcon
    this.navigation = navigationConst
  },
  methods: {
  }
}
</script>
