<template>
    <update-results></update-results>
</template>

<script>
import UpdateResults from './Update'
export default {
    name: "CreateResults",
    components: {
      UpdateResults
    }
}
</script>

<style scoped>

</style>