<template>

    <PageTitle title="Measure" buttonGroup="Create" routeLink="/measure/create"/>

    <div class="flex flex-col">
        <!-- Manufacturer List -->
        <div class="px-4 sm:px-6 lg:px-8">
            <div class="-mx-4 mt-8 overflow-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Id</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Type</th>
                            <th scope="col" class="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">Name</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 text-center text-sm font-semibold sm:pr-6">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="object in objects">
                            <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ object.id }}</td>
                            <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ object.type }}</td>
                            <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ object.name }}</td>
                            <td class="py-4 pl-3 pr-4 text-center text-sm inline-flex font-medium sm:pr-6">
                                <router-link :to="{ name: 'UpdateMeasure', params: { id: object.id }}">
                                    <img :src="this.editIcon" class="h-5 w-5" alt="Edit Measure">
                                </router-link>
                                <a href="#" class="ml-4" @click="deleteObject(object.id)">
                                    <img :src="this.deleteIcon" class="h-5 w-5" alt="Delete Measure">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Paginator :rows="per_page" :totalRecords="total" @page="onPage($event)"></Paginator>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle.vue"
import Paginator from "primevue/paginator";
import EditIcon from '../../images/button-icons/edit-icon.svg'
import DeleteIcon from '../../images/button-icons/delete-icon.svg'
import { redirectTimeout } from "../helper";

export default {
    name: "Measure",
    components: {
        PageTitle,
        Paginator
    },
    data() {
        return {
            editIcon:"",
            deleteIcon:"",
            end_point:"measure",
            errors:{},
            objects: [],
            per_page:10,
            total:0,
            query:{
                page:1
            }
        }
    },
    created() {
        this.editIcon = EditIcon
        this.deleteIcon = DeleteIcon
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        onPage($event){
            let query = this.query;
            query['page'] = $event.page + 1;
            this.fetchData(query);
        },
        moment: function (date) {
            return moment(date).format("MM/DD/yyyy hh:mm");
        },
        fetchData(query = null) {
            query = query || this.query;


                this.$axios.get(`/api/${this.end_point}`, {params:query}).then(response => {
                    const result = response.data || {};
                    this.objects = result.data.data || [];
                    this.per_page   = result.data.per_page;
                    this.total      = result.data.total;

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        deleteObject(id){
            this.$confirm.require({
                message: 'Are you sure you want to proceed?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {

                        this.$axios.delete(`/api/${this.end_point}/${id}`).then(response => {
                            const result = response.data;
                            if(result.message){
                                this.$toast.add({
                                    severity:result.success?'success':'error',
                                    summary: 'Notice',
                                    detail:result.message,
                                    life: 5000
                                });
                            }

                            if(result.success){
                                this.fetchData();
                            }

                        }).catch(error => {
                          if(error.response.status === 401) {
                            redirectTimeout()
                          }
                        });
                },
                reject: () => {
                }
            });

            return false;
        },
    },
    beforeRouteEnter(to, from, next) {
        if (!window.Laravel.isLoggedin) {
          redirectTimeout();
        }
        next();
    }
}
</script>
