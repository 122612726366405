<template>
<!--    <nav class="navbar navbar-expand-lg navbar-light bg-light">-->
<!--        <div class="collapse navbar-collapse">-->
<!--            <div class="navbar-nav">-->
<!--                <router-link to="/dashboard" class="nav-item nav-link">Dashboard</router-link>-->
<!--                <router-link to="/products" class="nav-item nav-link">Cost Build</router-link>-->
<!--                <router-link to="/ingredients" class="nav-item nav-link">Actives</router-link>-->
<!--                <router-link to="/excipients" class="nav-item nav-link">Excipient</router-link>-->
<!--                <router-link to="/packaging" class="nav-item nav-link">Packaging</router-link>-->
<!--                <router-link to="/register" class="nav-item nav-link">User</router-link>-->
<!--            </div>-->
<!--        </div>-->
<!--    </nav>-->
    <div class="flex flex-row mt-3 ml-1">
        <div class="basis-full md:basis-2/3 align-middle">
            <div class="italic">
                Welcome, {{ name }}!
            </div>
        </div>
        <div class="basis-full md:basis-1/3">
            <div class="form-group has-search relative">
                <span class="fa fa-search form-control-feedback absolute top-3 left-3"></span>
                <input type="text" class="form-control un-border-radius pl-8" placeholder="Search">
            </div>
        </div>
    </div>
    <div class="flex flex-wrap mt-5 ml-1 mr-1 border-b text-center">
        <div class="basis-1/2 md:basis-1/4 p-3">
            <div class="bg-offwhite">
                <router-link to="/ingredients">
                    <img class="mx-auto" src="/storage/img/icon.png">
                </router-link>
                <div class="direction mt-4">Ingredients</div>
            </div>
        </div>
        <div class="basis-1/2 md:basis-1/4 bg-offwhite p-3">
            <div class="bg-offwhite">
                <img class="menu-icon" src="/storage/img/icon.png">
                <div class="direction mt-4">Excipients</div>
            </div>
        </div>
        <div class="basis-1/2 md:basis-1/4 p-3">
            <div class="bg-offwhite">
                <img class="menu-icon" src="/storage/img/icon.png">
                <div class="direction mt-4">Formulas</div>
            </div>
        </div>
        <div class="basis-1/2 md:basis-1/4 p-3">
            <div class="bg-offwhite">
                <img class="menu-icon" src="/storage/img/icon.png">
                <div class="direction mt-4">Documents</div>
            </div>
        </div>
    </div>
    <div class="flex flex-wrap ml-1 mt-2">
        <div class="basis-full md:basis-3/4 mb-8 md:mb-0 overflow-x-auto">
            <div class="italic mb-2">Recent files</div>
                <table class="table-auto w-full text-m text-center leading-loose">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Creator</th>
                            <th>Accessed</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>02805</td>
                            <td>Mr. Hyde Signature 30srv Lollipop Punch</td>
                            <td>John Nguyen</td>
                            <td>1 Hour Ago</td>
                            <td>In Progress</td>
                            <td>Edit</td>
                        </tr>
                        <tr>
                            <td>02806</td>
                            <td>Mr. Hyde Signature 30srv Blue Raspberry Popsicle</td>
                            <td>John Nguyen</td>
                            <td>1 Hour Ago</td>
                            <td>In Progress</td>
                            <td>Edit</td>
                        </tr>
                        <tr>
                            <td>81260</td>
                            <td>Hyde Nightmare 30srv Lightning Lemon</td>
                            <td>John Nguyen</td>
                            <td>2 Hours Ago</td>
                            <td>In Review</td>
                            <td>Edit</td>
                        </tr>
                        <tr>
                            <td>81261</td>
                            <td>Hyde Nightmare 30srv Blood Berry</td>
                            <td>John Nguyen</td>
                            <td>3 Hours Ago</td>
                            <td>In Review</td>
                            <td>Edit</td>
                        </tr>
                        <tr>
                            <td>81262</td>
                            <td>Hyde Nightmare 30srv Jawbreaker</td>
                            <td>John Nguyen</td>
                            <td>4 Hours Ago</td>
                            <td>Completed</td>
                            <td>Edit</td>
                        </tr>                              
                    </tbody>
                </table>
            </div>
            <div class="basis-full md:basis-1/4">
                <div class="italic mb-2">Preview</div>
                    <div class="border p-1">
                        <img src="/storage/img/preview.png">
                    </div>
                </div>
            </div>

</template>

<script>
import { redirectTimeout } from "../helper";
export default {
    name: "Dashboard",
    data() {
        return {
            name: null,
        }
    },
    created() {
        if (window.Laravel.user) {
            this.name = window.Laravel.user.name
        }
    },
    beforeRouteEnter(to, from, next) {
        if (!window.Laravel.isLoggedin) {
          redirectTimeout();
        }
        next();
    }
}
</script>
