<template>
  <PageTitle :title="`Results - ${id?'Edit: ':'New '}`+ object.tested_product.name" buttonGroup="Save" buttonCancel="Cancel"
             buttonDuplicate="Duplicate" @duplicate="duplicate" @save="submit" @back="back"/>

  <div class="w-full max-w-md px-2 py-8 sm:px-6">
      <TabGroup>
        <TabList class="flex space-x-4 overflow-x-auto">
          <Tab as="template" v-slot="{ selected }">
            <button :class="{ 'bg-dark-gunmetal-100 text-dark-gunmetal px-3 py-2 font-medium text-sm rounded-md ui-selected:ring-offset-transparent': selected, 'text-dark-gunmetal-500 hover:text-dark-gunmetal-700  px-3 py-2 font-medium text-sm rounded-md ui-selected:ring-offset-transparent': !selected }">General</button>
          </Tab>
          <Tab as="template" v-slot="{ selected }">
            <button :class="{ 'bg-dark-gunmetal-100 text-dark-gunmetal px-3 py-2 font-medium text-sm rounded-md ui-selected:ring-0': selected, 'text-dark-gunmetal-500 hover:text-dark-gunmetal-700  px-3 py-2 font-medium text-sm rounded-md ui-selected:ring-offset-transparent': !selected }">Results</button>
          </Tab>
          <Tab as="template" v-slot="{ selected }">
            <button :class="{ 'bg-dark-gunmetal-100 text-dark-gunmetal px-3 py-2 font-medium text-sm rounded-md ui-selected:ring-0': selected, 'text-dark-gunmetal-500 hover:text-dark-gunmetal-700  px-3 py-2 font-medium text-sm rounded-md ui-selected:ring-offset-transparent': !selected }">Documents</button>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel class="bg-white p-3">
            <div class="md:flex md:space-x-4 lg:pb-8">
              <div class="mt-6 grid grid-cols-12 gap-4 md:w-1/2">
                <div class="relative col-span-12">

                  <select v-model="object.tested_product.manufacturer_id" id="manufacturer_id">
                    <option v-for="obj in master_data.manufacturers" :value="obj.id">{{obj.name}}</option>
                  </select>
                  <label for="manufacturer_id" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Manufacturer</label>
                  <small v-if="(v$.object.tested_product.manufacturer_id.$invalid && submitted) || v$.object.tested_product.manufacturer_id.$pending.$response" class="p-error">{{v$.object.tested_product.manufacturer_id.required.$message.replace('Value', 'Manufacturer')}}</small>
                </div>
                <div class="relative col-span-12">
                  <select v-model="object.tested_product.lab_id" id="lab_id">
                    <option value=""></option>
                    <option v-for="obj in master_data.labs" :value="obj.id">{{obj.name}}</option>
                  </select>
                  <label for="lab_id" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Lab</label>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.product_line"
                      id="product_line"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="product_line" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Product Line</label>
                  <small v-if="(v$.object.tested_product.product_line.$invalid && submitted) || v$.object.tested_product.product_line.$pending.$response" class="p-error">{{v$.object.tested_product.product_line.required.$message.replace('Value', 'Product Line')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.sku"
                      id="sku"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="sku" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">SKU</label>
                  <small v-if="(v$.object.tested_product.sku.$invalid && submitted) || v$.object.tested_product.sku.$pending.$response" class="p-error">{{v$.object.tested_product.sku.required.$message.replace('Value', 'SKU')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.upc_a"
                      id="upc_a"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="upc_a" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">UPC</label>
                  <small v-if="(v$.object.tested_product.upc_a.$invalid && submitted) || v$.object.tested_product.upc_a.$pending.$response" class="p-error">{{v$.object.tested_product.upc_a.required.$message.replace('Value', 'UPC')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.name"
                      id="name"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="name" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Product Name</label>
                  <small v-if="(v$.object.tested_product.name.$invalid && submitted) || v$.object.tested_product.name.$pending.$response" class="p-error">{{v$.object.tested_product.name.required.$message.replace('Value', 'Product Name')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.flavor"
                      id="flavor"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="flavor" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Flavor</label>
                  <small v-if="(v$.object.tested_product.flavor.$invalid && submitted) || v$.object.tested_product.flavor.$pending.$response" class="p-error">{{v$.object.tested_product.flavor.required.$message.replace('Value', 'Flavor')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.lot_number"
                      id="lot_number"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="lot_number" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Lot Number</label>
                  <small v-if="(v$.object.tested_product.lot_number.$invalid && submitted) || v$.object.tested_product.lot_number.$pending.$response" class="p-error">{{v$.object.tested_product.lot_number.required.$message.replace('Value', 'Lot Number')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.lot_expiration"
                      id="lot_expiration"
                      type="date"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="lot_expiration" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Expiration</label>
                  <small v-if="(v$.object.tested_product.lot_expiration.$invalid && submitted) || v$.object.tested_product.lot_expiration.$pending.$response" class="p-error">{{v$.object.tested_product.lot_expiration.required.$message.replace('Value', 'Expiration')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.sublot"
                      id="sublot"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="sublot" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Sublot</label>
                  <small class="p-error"></small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.manufacturing_date"
                      id="manufacturing_date"
                      type="date"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="manufacturing_date" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Manufacturing Date</label>
                  <small v-if="(v$.object.tested_product.manufacturing_date.$invalid && submitted) || v$.object.tested_product.manufacturing_date.$pending.$response" class="p-error">{{v$.object.tested_product.manufacturing_date.required.$message.replace('Value', 'Manufacturing Date')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.shelf_life"
                      id="shelf_life"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="shelf_life" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Shelf Life</label>
                  <small v-if="(v$.object.tested_product.shelf_life.$invalid && submitted) || v$.object.tested_product.shelf_life.$pending.$response" class="p-error">{{v$.object.tested_product.shelf_life.required.$message.replace('Value', 'shelf_life')}}</small>
                </div>
                <div class="relative col-span-12">
                  <select v-model="object.tested_product.life_uom" id="life_uom">
                    <option value=""></option>
                    <option v-for="obj in master_data.life_uoms" :value="obj.name">{{obj.name}}</option>
                  </select>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.packaging"
                      id="packaging"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="packaging" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Packaging</label>
                  <small v-if="(v$.object.tested_product.packaging.$invalid && submitted) || v$.object.tested_product.packaging.$pending.$response" class="p-error">{{v$.object.tested_product.packaging.required.$message.replace('Value', 'packaging')}}</small>
                </div>
                <div class="relative col-span-12">
                  <input
                      v-model="object.tested_product.serving_size"
                      id="serving_size"
                      type="text"
                      class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                      required="" />
                  <label for="serving_size" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Serving Size</label>
                  <small v-if="(v$.object.tested_product.serving_size.$invalid && submitted) || v$.object.tested_product.serving_size.$pending.$response" class="p-error">{{v$.object.tested_product.serving_size.required.$message.replace('Value', 'serving_size')}}</small>
                </div>
                <div class="relative col-span-12">
                  <select v-model="object.tested_product.serving_uom" id="serving_uom">
                    <option value=""></option>
                    <option v-for="obj in master_data.uom_list" :value="obj.name">{{obj.name}}</option>
                  </select>
                </div>
                <div class="relative col-span-12">
                  <select v-model="object.tested_product.release_decision" id="release_decision">
                    <option v-for="obj in master_data.status_list" :value="obj.id">{{obj.name}}</option>
                  </select>
                  <label for="release_decision" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Release Status</label>
                  <small v-if="(v$.object.tested_product.release_decision.$invalid && submitted) || v$.object.tested_product.release_decision.$pending.$response" class="p-error">{{v$.object.tested_product.release_decision.required.$message.replace('Value', 'release_decision')}}</small>
                </div>
              </div>
              <div class="mt-6 w-full md:w-1/2">
                <!-- Images Placeholder -->
<!--                <img class="h-auto max-w-xs" :src="object.tested_product.image_src?object.tested_product.image_src:'https://place-hold.it/300x300/666/fff/000'" alt="Image">-->
                <div class="grid gap-4">
                  <div class="ml-12">
                    <img class="rounded-lg" height="300" width="300" :src="object.tested_product.image_src?object.tested_product.image_src:'https://place-hold.it/300x300/666/fff/000'" alt="">
                  </div>
                  <div class="grid grid-cols-8 gap-4">
                    <div v-for="obj in object.images" style="position: relative">
                      <span class="close" style="position: absolute; right: 2px;z-index: 100;cursor: pointer;" @click="deleteImage(obj)">&times;</span>
                      <img class="h-auto max-w-xs rounded-lg" style="cursor: pointer" @click="viewImage(obj.image_src)" :src="obj.image_src?obj.image_src:'https://place-hold.it/300x300/666/fff/000'" alt="">
                    </div>
                  </div>
                </div>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 mx-auto border-2 border-gray border-dashed rounded-md">
                    <div class="space-y-1 text-center">
                      <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <div class="flex text-sm text-gray-600" @dragover.prevent @drop="onDrop">
                        <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium focus-within:outline-none">
                          <span><b>Upload files</b></span>
                          <input id="file-upload" name="file-upload" type="file" multiple class="sr-only" @change="onChange" accept="image/*" />
                        </label>
                        <p class="pl-1">or drag and drop</p>
                      </div>
                      <p class="text-xs text-gray">PNG, JPG, GIF up to 10MB</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </TabPanel>
          <TabPanel class="bg-white p-3">
            <div class="mt-6 grid grid-cols-12 gap-4 md:w-1/2">
              <h3>Specification</h3>
              <div class="relative col-span-12">
                <select v-model="test_result.test_type" id="test_type" @change="loadAnalysis(test_result.test_type)">
                  <option v-for="val in test_types" :value="val">{{val}}</option>
                </select>
                <label for="test_type" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Type</label>
              </div>
              <div class="relative col-span-12">
                <select v-model="test_result.analysis" id="analysis">
                  <option v-for="obj in master_data.analysis_list" :value="obj.name">{{obj.name}}</option>
                </select>
                <label for="analysis" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Analysis</label>
              </div>
              <div class="relative col-span-12">
                <select v-model="test_result.min_spec_operator" id="min_spec_operator">
                  <option value=""></option>
                  <option v-for="obj in master_data.operator_list" :value="obj.name">{{obj.name}}</option>
                </select>
                <label for="min_spec_operator" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Operator</label>
              </div>
              <div class="relative col-span-12">
                <input
                    v-model="test_result.min_specification"
                    id="min_specification"
                    type="text"
                    class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                    required="" />
                <label for="min_specification" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Min</label>
              </div>
              <div class="relative col-span-12">
                <select v-model="test_result.max_spec_operator" id="max_spec_operator">
                  <option value=""></option>
                  <option v-for="obj in master_data.operator_list" :value="obj.name">{{obj.name}}</option>
                </select>
                <label for="max_spec_operator" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Operator</label>
              </div>
              <div class="relative col-span-12">
                <input
                    v-model="test_result.max_specification"
                    id="max_specification"
                    type="text"
                    class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                    required="" />
                <label for="max_specification" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Max</label>
              </div>
              <div class="relative col-span-12">
                <select v-model="test_result.specification_uom" id="specification_uom">
                  <option value=""></option>
                  <option v-for="obj in master_data.uom_list" :value="obj.name">{{obj.name}}</option>
                </select>
                <label for="specification_uom" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">UoM</label>
              </div>
              <h3>Result</h3>
              <div class="relative col-span-12">
                <select v-model="test_result.result_operator" id="result_operator">
                  <option value=""></option>
                  <option v-for="obj in master_data.operator_list" :value="obj.name">{{obj.name}}</option>
                </select>
                <label for="result_operator" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Operator</label>
              </div>
              <div class="relative col-span-12">
                <input
                    v-model="test_result.result"
                    id="result"
                    type="text"
                    class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
                    required="" />
                <label for="result" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Result</label>
              </div>
              <div class="relative col-span-12">
                <select v-model="test_result.result_uom" id="result_uom" ref="result_uom">
                  <option value=""></option>
                  <option v-for="obj in master_data.uom_list" :value="obj.name">{{obj.name}}</option>
                </select>
                <label for="result_uom" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">UoM</label>
              </div>
              <div class="relative col-span-12">
                <select v-model="test_result.method" id="method">
                  <option v-for="obj in master_data.methods" :value="obj.name">{{obj.name}}</option>
                </select>
                <label for="method" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Test Method</label>
              </div>
              <div class="relative col-span-12">
                <select v-model="test_result.status" id="status">
                  <option v-for="obj in master_data.test_status_list" :value="obj.name">{{obj.name}}</option>
                </select>
                <label for="status" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Status</label>
              </div>
            </div>
            <div class="relative col-span-12">
              <button type="button" class="float-right rounded inline-flex items-center px-2.5 py-1.5 font-medium text-white bg-dark-gunmetal" @click="addResult()">
                {{ test_results_button_text }}</button>
            </div>
            <div class="md:flex md:space-x-4 lg:pb-12">
              <div class="flex flex-col">
                <div class="px-4 sm:px-6 lg:px-8">
                  <div class="-mx-4 mt-8 overflow-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                      <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Type</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Analysis</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Op.</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Min</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Op.</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Max</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">UoM</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Op.</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Result</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">UoM</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Test Method</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Pass/Fail</th>
                        <th scope="col" class="relative py-3.5 pl-3 pr-4 text-center text-sm font-semibold sm:pr-6">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="obj in object.test_results">
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.test_type }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.analysis }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.min_spec_operator }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.min_specification }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.max_spec_operator }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.max_specification }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.specification_uom }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.result_operator }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.result }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.result_uom }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.method }}</td>
                        <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">{{ obj.status }}</td>
                        <td class="py-4 pl-3 pr-4 text-center text-sm inline-flex font-medium sm:pr-6">
                          <a href="#" class="ml-4" @click="editResult(obj.id)">
                            <img :src="this.editIcon" class="h-5 w-5" alt="Edit">
                          </a>
                          <a href="#" class="ml-4" @click="deleteResult(obj.id)">
                            <img :src="this.deleteIcon" class="h-5 w-5" alt="Delete">
                          </a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <Paginator :rows="per_page" :totalRecords="total" @page="onPage($event)"></Paginator>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel class="bg-white p-3">
            <!-- Documents -->
            <div class="flex justify-between">
              <div class="grid grid-cols-6 gap-4">
                <div><input type="file" placeholder="Select File" id="document_file" @change="documentFileChange"></div>
                <button type="button"
                        class="float-right rounded block items-center px-2.5 py-1.5 font-medium text-white bg-dark-gunmetal text-center"
                        @click="addDocument"
                >{{document.id?'Edit':'Upload'}} Document</button>
              </div>
              <div>

              </div>
            </div>
            <div class="flex flex-col">
              <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div class="overflow-hidden">
                    <table class="min-w-full divide-y">
                      <thead>
                      <tr>
                        <th scope="col" class="whitespace-nowrap py-3.5 px-2 text-left text-sm font-semibold">
                          Filename
                        </th>
                        <th scope="col" class="whitespace-nowrap py-3.5 px-2 text-center text-sm font-semibold">
                          Type
                        </th>
                        <th scope="col" class="whitespace-nowrap py-3.5 px-2 text-center text-sm font-semibold">
                          Last Updated
                        </th>
                        <th scope="col" class="whitespace-nowrap py-3.5 px-2 text-center text-sm font-semibold">
                          Action
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="doc in object.documents">
                        <td class="whitespace-nowrap p-2 text-sm text-ellipsis overflow-hidden">{{ doc.file_name }}</td>
                        <td class="whitespace-nowrap p-2 text-sm text-center">{{ doc.file_type }}</td>
                        <td class="whitespace-nowrap p-2 text-sm text-center">{{ momentDate(doc.updated_at) }}</td>
                        <td class="whitespace-nowrap p-2 text-md text-center">
                          <a href="#" class="p-1" @click="downloadDocument(doc)" v-if="doc.file_path"><span class="fa fa-eye"></span></a>
                          <a href="#" class="p-1" @click="removeDocument(doc.id)"><span class="fa fa-times"></span></a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
</template>

<script>


import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import PageTitle from "../../components/PageTitle.vue"
import Paginator from "primevue/paginator";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import EditIcon from '../../../images/button-icons/edit-icon.svg';
import DeleteIcon from '../../../images/button-icons/delete-icon.svg';
import moment from 'moment';
import { redirectTimeout,getAnalysisTypes } from "../../helper";

const analysis_types = getAnalysisTypes();

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
      TabGroup,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      PageTitle,
      Paginator,
    },
    name: "UpdateResults",
    data() {
      return {
        editIcon:"",
        deleteIcon:"",
        componentList: "Results",
        componentCreate: "CreateResults",
        id: this.$route.params.id || false,
        submitted: false,
        end_point:"results",
        per_page:30,
        total:0,
        test_types:[],
        master_data:{
          manufacturers:[{val:"",name:"Select"}],
          labs:[{val:"",name:"Select"}],
          life_uoms:[{val:"",name:"Select"}],
          status_list:[{val:"",name:"Select"}],
          analysis_list:[{val:"",name:"Select"}],
          operator_list:[{val:"",name:"Select"}],
          uom_list:[{val:"",name:"Select"}],
          methods:[{val:"",name:"Select"}],
          test_status_list:[{val:"",name:"Select"}],
        },
        object:{
          tested_product:{
            manufacturer_id:'',
            lab_id:'',
            name:'',
            flavor:'',
            sublot:'',
            product_line:'',
            sku:'',
            upc_a:'',
            lot_number:'',
            lot_expiration:'',
            packaging:'',
            serving_size:'',
            serving_uom:'',
            manufacturing_date:'',
            shelf_life:'',
            life_uom:'',
            release_decision:'',
            image_path:'',
            image_src:'',
          },
          test_results:[],
          documents:[],
          images:[],
        },
        image:{
          id:'',
          image_path:'',
          image_src:'',
        },
        document: {
          id:"",
          file_name:"",
          file_path:"",
          file_type:"",
          file:null
        },
        test_results_button_text:'Submit',
        test_result:{
          id:'',
          test_type:'',
          analysis:'',
          min_spec_operator:'',
          min_specification:'',
          max_spec_operator:'',
          max_specification:'',
          specification_uom:'',
          result_operator:'',
          result:'',
          result_uom:'',
          method:'',
          status:'',
        },
      }
    },
    created() {
      this.test_types = analysis_types;
      this.editIcon = EditIcon
      this.deleteIcon = DeleteIcon
    },
    mounted() {
      this.fetchData();
      this.masterData();
    },
    validations() {
        return {
            object:{
              tested_product:{
                manufacturer_id:{required},
                name:{required},
                flavor:{required},
                product_line:{required},
                sku:{required},
                upc_a:{required},
                lot_number:{required},
                lot_expiration:{required},
                packaging:{required},
                serving_size:{required},
                serving_uom:{required},
                manufacturing_date:{required},
                shelf_life:{required},
                life_uom:{required},
                release_decision:{required},
              }
            }

        }
    },
    methods: {
      removeDocument(id){
        this.object.documents = this.object.documents.filter(item => {
          return item.id !== id;
        });
      },
      downloadDocument(doc){

          this.$axios.get(`/api/upload-document/download`,{
            params:{
              path:doc.file_path
            },
            responseType: 'arraybuffer',
          }).then(response => {
            let blob = new Blob([response.data], {
              type: `application/${doc.file_type}`
            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = doc.file_name;
            link.click();
            link.remove();
          })
              .catch(error => {
                if(error.response.status === 401) {
                  redirectTimeout()
                }
              });
      },
      async uploadDocument(){

          let formData = new FormData();
          formData.append('file', this.document.file);
          formData.append('module', 'private');

          return this.$axios.post(`/api/upload-document`,
              formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
            const result = response.data;
            if(result.data){
              this.document.file_name = result.data.name;
              this.document.file_path = result.data.path;
              this.document.file_type = result.data.extension;
            }
          })
              .catch(error => {
                if(error.response.status === 401) {
                  redirectTimeout()
                }
              });
      },
      documentFileChange(e){
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length){
          this.document.file = null;
          return;
        }
        this.document.file = files[0];
      },

      async addDocument(){
        let submit = true;
        if(!this.document.id && !this.document.file){
          this.showErrorMessage("Document file required");
          submit = false;
        }

        if(!submit){
          return false;
        }

        if(this.document.file){
          await this.uploadDocument();
          this.document['file'] = null;
        }

        if(!this.document['id']){
          this.document['id'] =  Date.now();
          this.object.documents.push(this.document);
        }else{
          this.documents = this.documents.map(item => {
            if(item.id === this.document.id){
              return this.document;
            }
            return item;
          })
        }

        this.document = {
          id:"",
          file_name:"",
          file_path:"",
          file_type:"",
          file:null
        }
        document.getElementById('document_file').value = null;
      },
      momentDate(date){
        if(!date){
          return '-';
        }
        return moment(date).format("MM/DD/yyyy");
      },
      addResult(){
        //validate
        let submit = true;
        if(!this.test_result.test_type){
          this.showErrorMessage("Test Type is required");
          submit = false;
        }
        if(!this.test_result.analysis){
          this.showErrorMessage("Analysis is required");
          submit = false;
        }
        if(!this.test_result.max_specification){
          this.showErrorMessage("Max is required");
          submit = false;
        }
        if(!this.test_result.result){
          this.showErrorMessage("Result is required");
          submit = false;
        }
        if(!this.test_result.method){
          this.showErrorMessage("Test Method is required");
          submit = false;
        }
        if(!this.test_result.status){
          this.showErrorMessage("Status is required");
          submit = false;
        }
        if(this.test_result.result_uom != this.test_result.specification_uom){
          this.$confirm.require({
            message: 'UoM does not match. Would you like to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClass: 'p-button-danger',
            accept: () => {
              this.submitTestResult()
            },
            reject: () => {
              this.$refs.result_uom.focus();
            }
          });
        }else{
          if(!submit){
            return false;
          }
          this.submitTestResult()
        }

      },
      submitTestResult(){
        //submit
        if(!this.test_result['id']){
          this.test_result['id'] =  Date.now();
          this.object.test_results.push(this.test_result);
        }else{
          this.object.test_results = this.object.test_results.map(item => {
            if(item.id === this.test_result.id){
              return this.test_result;
            }
            return item;
          })
        }
        this.test_results_button_text = 'Submit'
        this.test_result = {
          id:'',
          test_type:'',
          analysis:'',
          min_spec_operator:'',
          min_specification:'',
          max_spec_operator:'',
          max_specification:'',
          specification_uom:'',
          result_operator:'',
          result:'',
          result_uom:'',
          method:'',
          status:'',
        }
      },
      editResult(id){
        const test_results = this.object.test_results.filter(item => {
          return item.id === id;
        });
        if(!test_results.length){
          return;
        }
        const deepClone = JSON.parse(JSON.stringify(test_results));
        this.loadAnalysis(deepClone[0].test_type);
        this.test_result = deepClone[0];
        this.test_results_button_text = 'Update'
      },
      deleteResult(id){
        this.object.test_results = this.object.test_results.filter(item => {
          return item.id !== id;
        });
      },
      loadAnalysis(type){

          this.$axios.get(`/api/master-data/`+type+`/loadAnalysis`).then(response => {
            const result = response.data;
            if(!result.success){
              if(result.message){
                console.error(result.message);
              }
              return false;
            }
            this.master_data.analysis_list = result.data.analysis_list;
          })
              .catch(error => {
                console.error(error);
              });
      },
      onPage($event){
        //let query = this.query;
        //query['page'] = $event.page + 1;
        //this.fetchData(query);
      },
      back(){
        this.$router.push({name:'Results', params:{}});
      },
        async submit(is_continue) {
            this.submitted = true;

            var isFormValid = await this.v$.$validate();

            if (!isFormValid) {
                return false;
            }

            if(this.id){
                this.update(is_continue);
            }else{
                this.create(is_continue);
            }

            return false;

        },
      duplicate(id){
        this.$confirm.require({
          message: 'Are you sure you want to duplicate this result id:' + id + '?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          acceptClass: 'p-button-danger',
          accept: () => {

            this.$axios.post(`/api/${this.end_point}/duplicate/${id}`).then(response => {
              const result = response.data;
              if(result.message){
                this.$toast.add({
                  severity:result.success?'success':'error',
                  summary: 'Notice',
                  detail:result.message,
                  life: 5000
                });
              }
              this.$router.push({name: this.componentList})

            }).catch(error => {
              if(error.response.status === 401) {
                redirectTimeout()
              }
            });
          },
          reject: () => {
          }
        });
      },
        create(is_continue){

                this.$axios.post(`/api/${this.end_point}`, this.object).then(response => {
                    const result = response.data;
                    if(result.message){
                        this.$toast.add({
                            severity:result.success?'success':'warn',
                            summary: 'Notice',
                            detail:result.message,
                            life: 5000,
                            group: 'br'
                        });
                    }
                    if(!result.success){
                        return false;
                    }
                  if(is_continue == 2){
                    return true;
                  }
                    if(!is_continue){
                        this.$router.push({name: this.componentList})
                    }else{
                        this.$router.push({name: this.componentCreate})
                    }

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        update(is_continue){

                this.$axios.put(`/api/${this.end_point}/${this.id}`, this.object).then(response => {
                    const result = response.data;
                    console.log(result);
                    if(result.message){
                        this.$toast.add({
                            severity:result.success?'success':'warn',
                            summary: 'Notice',
                            detail:result.message,
                            life: 5000,
                            group: 'br'
                        });
                    }
                    if(!result.success){
                        return false;
                    }
                  if(is_continue == 2){
                    return true;
                  }
                    if(!is_continue){
                        this.$router.push({name: this.componentList})
                    }else{
                        this.$router.push({name: this.componentCreate})
                    }

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        showErrorMessage(message){
            this.$toast.add({
                severity:'warn',
                summary: 'Error',
                detail:message,
                life: 5000,
                group: 'br'
            });
        },
        fetchData(){
            this.fetchObject();
        },
        fetchObject(){
            if(!this.id){
                return false;
            }


                this.$axios.get(`/api/${this.end_point}/${this.id}`).then(response => {
                    const result = response.data;
                    if(!result.success){
                        if(result.message){
                            this.showErrorMessage(result.message);

                        }
                        return false;
                    }
                    this.object.tested_product = result.data;
                    this.object.test_results = result.data.results;
                    this.object.documents = result.data.documents;
                    this.object.images = result.data.images;
                    this.total      = this.object.test_results.length;

                })
                    .catch(error => {
                      if(error.response.status === 401) {
                        redirectTimeout()
                      }
                    });
        },
      masterData(){

          this.$axios.get(`/api/master-data/${this.end_point}`).then(response => {
                const result = response.data;
                if(!result.success){
                  if(result.message){
                    console.error(result.message);
                  }
                  return false;
                }
                this.master_data = result.data;
               })
              .catch(error => {
                if(error.response.status === 401) {
                  redirectTimeout()
                }
              });
      },
      onDrop: function(e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;
        this.createFile(files);
      },
      onChange(e) {
        var files = e.target.files;
        this.createFile(files);
      },
      handleImages(files){
        this.createFile(files);
      },
      viewImage(image_src){
        this.object.tested_product.image_src = image_src;
      },
      deleteImage(image){
        this.object.images = this.object.images.filter(img => img.id != image.id);
      },
      async createFile(files) {
        if(!files.length){
          this.showErrorMessage('Select an image');
          return;
        }
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          if (!file.type.match('image.*')) {
            this.showErrorMessage('Select an image type');
            return;
          }
          await this.uploadImage(file);
        }
        document.getElementById('file-upload').value = null;
      },
      async uploadImage(file){
          let formData = new FormData();
          formData.append('module', 'results');
          formData.append('public', '1');
          formData.append('file', file);

          return this.$axios.post(`/api/upload-document`,
              formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
            const result = response.data;
            if(result.success){
              this.image.image_path = result.data.path;
              this.image.image_src = '/storage/'+result.data.path;
              if(!this.object.tested_product.image_src){
                this.viewImage(this.image.image_src);
              }
              if(!this.image['id']){
                this.image['id'] =  Date.now();
                this.object.images.push(this.image);
              }else{
                this.object.images = this.object.images.map(item => {
                  if(item.id === this.image.id){
                    return this.image;
                  }
                  return item;
                })
              }
              this.image ={
                id:'',
                image_path:'',
                image_src:'',
              }
            }else if(result.message){
              this.showErrorMessage(result.message);
            }
          })
              .catch(error => {
                if(error.response.status === 401) {
                  redirectTimeout()
                }
              });
      },

    },
  beforeRouteEnter(to, from, next) {
    if (!window.Laravel.isLoggedin) {
      redirectTimeout();
    }
    next();
  }
}
</script>
