<template>
    <update-labs></update-labs>
</template>

<script>
import UpdateLabs from './Update'
export default {
    name: "CreateLabs",
    components: {
        UpdateLabs
    }
}
</script>

<style scoped>

</style>