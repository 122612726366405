import {createWebHistory, createRouter} from "vue-router";


import Lookup from '../pages/Lookup';
import TestResult from '../pages/TestResult';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Manufacturers from '../pages/Manufacturers';
import Labs from '../pages/Labs';
import Analysis from '../pages/Analysis';
import Measure from '../pages/Measure';
import General from '../pages/General';
import Results from '../pages/Results';

import CreateManufacturer from '../components/Manufacturer/Create';
import UpdateManufacturer from '../components/Manufacturer/Update';
import CreateAnalysis from '../components/Analysis/Create';
import UpdateAnalysis from '../components/Analysis/Update';
import CreateMeasure from '../components/Measure/Create';
import UpdateMeasure from '../components/Measure/Update';
import CreateGeneral from '../components/General/Create';
import UpdateGeneral from '../components/General/Update';
import CreateLabs from '../components/Labs/Create';
import UpdateLabs from '../components/Labs/Update';
import CreateResults from '../components/Results/Create';
import UpdateResults from '../components/Results/Update';
import { getAdminPath } from "../helper";
import Users from "../pages/Users";
import CreateUser from "../components/User/Create";
import UpdateUser from "../components/User/Update";

const admin_path = getAdminPath();

export const routes = [
    //web config
    {
        name: 'TestResultLookup',
        path: '/test-result/lookup',
        component: Lookup
    },
    {
        name: 'TestResultPage',
        path: '/test-result/:upc/:lot_id/:id',
        component: TestResult
    },
    {
        name: 'home',
        path: '/',
        component: Lookup
    },
    //admin config
    {
        name: 'AdminPortal',
        path: admin_path,
        component: Login
    },
    {
        name: 'Login',
        path: admin_path+'/login',
        component: Login
    },
    {
        name: 'Dashboard',
        path: admin_path+'/dashboard',
        component: Dashboard
    },
    {
        name: 'Manufacturers',
        path: admin_path+'/manufacturers',
        component: Manufacturers
    },
    {
        name: 'CreateManufacturer',
        path: admin_path+'/manufacturer/create',
        component: CreateManufacturer
    },
    {
        name: 'UpdateManufacturer',
        path: admin_path+'/manufacturer/:id/edit',
        component: UpdateManufacturer
    },
    {
        name: 'Labs',
        path: admin_path+'/labs',
        component: Labs
    },
    {
        name: 'CreateLabs',
        path: admin_path+'/labs/create',
        component: CreateLabs
    },
    {
        name: 'UpdateLabs',
        path: admin_path+'/labs/:id/edit',
        component: UpdateLabs
    },
    {
        name: 'Analysis',
        path: admin_path+'/analysis',
        component: Analysis
    },
    {
        name: 'CreateAnalysis',
        path: admin_path+'/analysis/create',
        component: CreateAnalysis
    },
    {
        name: 'UpdateAnalysis',
        path: admin_path+'/analysis/:id/edit',
        component: UpdateAnalysis
    },
    {
        name: 'Measure',
        path: admin_path+'/measure',
        component: Measure
    },
    {
        name: 'CreateMeasure',
        path: admin_path+'/measure/create',
        component: CreateMeasure
    },
    {
        name: 'UpdateMeasure',
        path: admin_path+'/measure/:id/edit',
        component: UpdateMeasure
    },
    {
        name: 'General',
        path: admin_path+'/general',
        component: General
    },
    {
        name: 'CreateGeneral',
        path: admin_path+'/general/create',
        component: CreateGeneral
    },
    {
        name: 'UpdateGeneral',
        path: admin_path+'/general/:id/edit',
        component: UpdateGeneral
    },
    {
        name: 'Results',
        path: admin_path+'/results',
        component: Results
    },
    {
        name: 'CreateResults',
        path: admin_path+'/results/create',
        component: CreateResults
    },
    {
        name: 'UpdateResults',
        path: admin_path+'/results/:id/edit',
        component: UpdateResults
    },
    {
        name: 'Users',
        path: admin_path+'/users',
        component: Users
    },
    {
        name: 'CreateUser',
        path: admin_path+'/user/create',
        component: CreateUser
    },
    {
        name: 'UpdateUser',
        path: admin_path+'/user/:id/edit',
        component: UpdateUser
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

export default router;
