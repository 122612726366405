<template>
    <update-analysis></update-analysis>
</template>

<script>
import UpdateAnalysis from './Update'
export default {
    name: "CreateAnalysis",
    components: {
      UpdateAnalysis
    }
}
</script>

<style scoped>

</style>