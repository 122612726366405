<template>
  <PageTitle :title="`Lab - ${id?'Edit':'New'}`" buttonGroup="Save" @save="submit"/>
  <div class="w-full max-w-md px-2 py-8 sm:px-6">
    <div class="md:flex md:space-x-4 lg:pb-8">
      <div class="mt-6 grid grid-cols-12 gap-4 md:w-1/2">
        <div class="relative col-span-12">
          <input
              v-model="object.code"
              id="code"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="code" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Lab No.</label>

          <small v-if="(v$.object.code.$invalid && submitted) || v$.object.code.$pending.$response" class="p-error">{{v$.object.code.required.$message.replace('Value', 'Lab No.')}}</small>
        </div>
        <div class="relative col-span-12">
          <input
              v-model="object.name"
              id="name"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="name" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Lab Name</label>
          <small v-if="(v$.object.name.$invalid && submitted) || v$.object.name.$pending.$response" class="p-error">{{v$.object.name.required.$message.replace('Value', 'Lab Name')}}</small>
        </div>

        <div class="relative col-span-12">
          <input
              v-model="object.address"
              id="address"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="address" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Address</label>
          <small v-if="(v$.object.address.$invalid && submitted) || v$.object.address.$pending.$response" class="p-error">{{v$.object.address.required.$message.replace('Value', 'Address')}}</small>
        </div>

        <div class="relative col-span-12">
          <input
              v-model="object.address2"
              id="address2"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="address2" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Address 2</label>
        </div>

        <div class="relative col-span-12">
          <input
              v-model="object.city"
              id="city"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="city" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">City</label>
          <small v-if="(v$.object.city.$invalid && submitted) || v$.object.city.$pending.$response" class="p-error">{{v$.object.city.required.$message.replace('Value', 'City')}}</small>
        </div>

        <div class="relative col-span-12">
          <input
              v-model="object.state"
              id="state"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="state" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">State</label>
          <small v-if="(v$.object.state.$invalid && submitted) || v$.object.state.$pending.$response" class="p-error">{{v$.object.state.required.$message.replace('Value', 'State')}}</small>
        </div>

        <div class="relative col-span-12">
          <input
              v-model="object.zip_code"
              id="zip_code"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="zip_code" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Zip Code</label>
          <small v-if="(v$.object.zip_code.$invalid && submitted) || v$.object.zip_code.$pending.$response" class="p-error">{{v$.object.zip_code.required.$message.replace('Value', 'Zip Code')}}</small>
        </div>

        <div class="relative col-span-12">
          <input
              v-model="object.country"
              id="country"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="country" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Country</label>
          <small v-if="(v$.object.country.$invalid && submitted) || v$.object.country.$pending.$response" class="p-error">{{v$.object.country.required.$message.replace('Value', 'Country')}}</small>
        </div>

        <div class="relative col-span-12">
          <input
              v-model="object.phone"
              id="phone"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="phone" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Phone</label>
          <small v-if="(v$.object.phone.$invalid && submitted) || v$.object.phone.$pending.$response" class="p-error">{{v$.object.phone.required.$message.replace('Value', 'Phone')}}</small>
        </div>

        <div class="relative col-span-12">
          <input
              v-model="object.email"
              id="email"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="email" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Email</label>
          <small v-if="(v$.object.email.$invalid && submitted) || v$.object.email.$pending.$response" class="p-error">{{v$.object.email.required.$message.replace('Value', 'Email')}}</small>
        </div>

      </div>
    </div>

  </div>
</template>

<script>



import PageTitle from "../../components/PageTitle.vue"
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {redirectTimeout} from "../../helper";



export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        PageTitle
    },
    name: "UpdateLabs",
    data() {
        return {
            componentList: "Labs",
            componentCreate: "CreateLabs",
            id: this.$route.params.id || false,
            submitted: false,
            end_point:"labs",
            object:{
                code:"",
                name:"",
                address:"",
                address2:"",
                city:"",
                state:"",
                zip_code:"",
                country:"",
                phone:"",
                email:"",
            },
        }
    },
    created() {
    },
    mounted() {
        this.fetchData();
    },
    validations() {
        return {
            object:{
                name: {
                    required
                },
                email: {
                    required,
                    email
                },
                code:{
                    required
                },
                address:{
                    required
                },
                address2:"",
                city:{
                    required
                },
                state:{
                    required
                },
                zip_code:{
                    required
                },
                country:{
                    required
                },
                phone:{
                    required
                }
            }

        }
    },
    methods: {
        async submit(is_continue) {
            this.submitted = true;

            const isFormValid = await this.v$.$validate();

            if (!isFormValid) {
                return false;
            }

            if(this.id){
                this.update(is_continue);
            }else{
                this.create(is_continue);
            }

            return false;

        },
        create(is_continue){

                this.$axios.post(`/api/${this.end_point}`, this.object).then(response => {
                    const result = response.data;
                    if(result.message){
                        this.$toast.add({
                            severity:result.success?'success':'warn',
                            summary: 'Notice',
                            detail:result.message,
                            life: 5000,
                            group: 'br'
                        });
                    }
                    if(!result.success){
                        return false;
                    }
                  if(is_continue == 2){
                    return true;
                  }
                    if(!is_continue){
                        this.$router.push({name: this.componentList})
                    }else{
                        this.$router.push({name: this.componentCreate})
                    }

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        update(is_continue){

                this.$axios.put(`/api/${this.end_point}/${this.id}`, this.object).then(response => {
                    const result = response.data;
                    if(result.message){
                        this.$toast.add({
                            severity:result.success?'success':'warn',
                            summary: 'Notice',
                            detail:result.message,
                            life: 5000,
                            group: 'br'
                        });
                    }
                    if(!result.success){
                        return false;
                    }
                  if(is_continue == 2){
                    return true;
                  }
                    if(!is_continue){
                        this.$router.push({name: this.componentList})
                    }else{
                        this.$router.push({name: this.componentCreate})
                    }

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        showErrorMessage(message){
            this.$toast.add({
                severity:'warn',
                summary: 'Error',
                detail:message,
                life: 5000,
                group: 'br'
            });
        },
        fetchData(){
            this.fetchObject();
        },
        fetchObject(){
            if(!this.id){
                return false;
            }


                this.$axios.get(`/api/${this.end_point}/${this.id}`).then(response => {
                    const result = response.data;
                    if(!result.success){
                        if(result.message){
                            this.showErrorMessage(result.message);
                        }
                        return false;
                    }
                    this.object = result.data;

                })
                    .catch(error => {
                      if(error.response.status === 401) {
                        redirectTimeout()
                      }
                    });
        },

    },
  beforeRouteEnter(to, from, next) {
    if (!window.Laravel.isLoggedin) {
      redirectTimeout();
    }
    next();
  }
}
</script>
