<template>
    <PageTitle title="Users" buttonGroup="Create" routeLink="/user/create"/>

    <div class="px-4 sm:px-6 lg:px-8">
      <div class="-mx-4 mt-8 overflow-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Username</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
              <th scope="col" class="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">Email</th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Phone</th>
              <th scope="col" class="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">Updated On</th>
              <th scope="col" class="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">Created On</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 text-right sm:pr-6">Action</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="user in users" :key="user.id">
              <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ user.user_name }}</td>
              <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">{{ user.name }}</td>
              <td class="hidden px-3 py-4 text-center text-sm text-gray-500 sm:table-cell">{{ user.email }}</td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ user.phone }}</td>
              <td class="hidden px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">{{ user.updated_at }}</td>
              <td class="hidden px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">{{ user.created_at }}</td>
              <td class="py-4 pl-3 pr-4 text-right text-sm inline-flex font-medium sm:pr-6">
                <router-link :to="{name: 'UpdateUser', params: { id: user.id }}">
                  <img :src="this.editIcon" class="h-5 w-5" alt="Edit User">
                </router-link>
                <a href="#" @click="deleteUser(user.id,$event)" class="ml-4">
                  <img :src="this.deleteIcon" class="h-5 w-5" alt="Delete User">
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

</template>

<script>
import PageTitle from "../components/PageTitle.vue"

import EditIcon from '../../images/button-icons/edit-icon.svg'
import DeleteIcon from '../../images/button-icons/delete-icon.svg'
import {redirectTimeout} from "../helper";

export default {
    name: "Users",
    components: {
      PageTitle
    },
    data() {
      return {
          users: []
      }
    },
    created() {

        this.$axios.get('/api/users')
            .then(response => {
              this.users = response.data.data.users.data;
            })
            .catch(function (error) {
              if(error.response.status === 401) {
                redirectTimeout()
              }
            });

      this.editIcon = EditIcon
      this.deleteIcon = DeleteIcon
    },
    methods: {
      deleteUser(id,e){
        e.preventDefault();
        this.$confirm.require({
          message: 'Are you sure you want to proceed?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          acceptClass: 'p-button-danger',
          accept: () => {
            this.$axios.delete('/api/users/delete/'+id)
                .then(response => {
                  const result = response.data;
                  if(result.message){
                    this.$toast.add({
                      severity:result.success?'success':'error',
                      summary: 'Notice',
                      detail:result.message,
                      life: 5000
                    });
                  }
                  if(result.success){
                    this.users = response.data.data.users.data
                  }
                })
                .catch(function (error) {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });

          },
          reject: () => {
          }
        });

      },
    },
    beforeRouteEnter(to, from, next) {
      if (!window.Laravel.isLoggedin) {
        window.location.href = "/";
      }
      next();
    }
}
</script>