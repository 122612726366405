<template>
    <ConfirmDialog />
    <Toast />
    <Toast position="bottom-right" group="br" />
    <div id="mmenu_screen"  class="h-full leading-normal tracking-normal">
        <div class="h-full flex" v-if="isLoggedIn">

            <!-- Mobile offcanvas menu using HeadlessUI Dialog and Transition -->
            <TransitionRoot as="template" :show="sidebarOpen">
              <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-dark-gunmetal bg-opacity-75"></div>
                </TransitionChild>
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
                <div class="relative flex-1 flex flex-col max-w-2/3 w-full bg-dark-gunmetal">
                    <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="absolute top-0 right-0 -mr-12 pt-2">
                        <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                        <span class="sr-only">Close sidebar</span>
                        <svg class="h-6 w-6 text-white" x-description="Heroicon name: outline/x" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                        </button>
                    </div>
                    </TransitionChild>
                    <div class="flex-1 h-0 pb-4 overflow-y-auto">
                    <nav class="mt-5 px-2 space-y-1">
                        <template v-for="item in navigation_menu" :key="item.name">

                            <div v-if="!item.children">
                                <router-link :to="{path:item.href} " @click="logout($event,item.name)" :class="[item.current ? 'bg-gray text-white' : 'text-white' , 'group w-full flex pl-2 py-2 text-base font-medium rounded-md']">
                                    <img :src="item.icon" :class="['mr-3 flex-shrink-0 h-6 w-6']" viewBox="0 0 22 22" aria-hidden="true">
                                    {{ item.name }}
                                </router-link>
                            </div>
                            <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
                                <DisclosureButton :class="[item.current ? 'bg-gray text-white' : 'text-white' , 'group w-full flex pl-2 py-2 text-base text-left font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo']">
                                    <img :src="item.icon" :class="['mr-4 flex-shrink-0 h-6 w-6']" viewBox="0 0 22 22" aria-hidden="true">
                                    <span class="flex-1">
                                        {{ item.name }}
                                    </span>
                                    <svg :class="[open ? 'text-white rotate-90' : 'text-white', 'ml-3 h-6 w-6 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray']" viewBox="0 0 22 22" aria-hidden="true">
                                        <path d="M6 6L14 10L6 14V6Z" fill="white" />
                                    </svg>
                                </DisclosureButton>
                                <DisclosurePanel class="space-y-1 -mx-2 bg-dark-gunmetal-850 shadow-inner">
                                    <DisclosureButton v-for="subItem in item.children" :key="subItem.name" as="a" :href="subItem.href" class="group flex w-full items-center rounded-md py-2 pl-14 pr-2 text-base font-medium text-white hover:bg-gray hover:text-white">{{ subItem.name }}</DisclosureButton>
                                </DisclosurePanel>
                            </Disclosure>
                        </template>
                    </nav>
                    </div>
                    <div class="flex-shrink-0 flex bg-dark-gunmetal-800 p-4">
                    <a href="#" class="flex-shrink-0 group block">
                        <div class="flex items-center">
                        <div>
                            <img class="inline-block h-10 w-10 rounded-full" src="https://via.placeholder.com/40" alt="" />
                        </div>
                        <div class="ml-3">
                            <p class="text-base font-medium text-white">{{ name }}</p>
                            <p class="text-sm font-medium text-white">View profile</p>
                        </div>
                        </div>
                    </a>
                    </div>
                </div>
                </TransitionChild>
                <div class="flex-shrink-0 w-14">
                <!-- Force sidebar to shrink to fit close icon -->
                </div>
            </Dialog>
            </TransitionRoot>

            <!-- Sidebar for Desktop -->
            <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
                <div class="flex min-h-0 flex-1 flex-col bg-dark-gunmetal">
                    <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                        <nav class="px-2 space-y-1">
                        <template v-for="item in navigation_menu" :key="item.name">

                            <div v-if="!item.children">
                                <router-link :to="{path:item.href} " @click="logout($event,item.name)" :class="[item.current ? 'bg-gray text-white' : 'text-white' , 'group w-full flex pl-2 py-2 text-base font-medium rounded-md']">
                                    <img :src="item.icon" :class="['mr-3 flex-shrink-0 h-6 w-6']" viewBox="0 0 22 22" aria-hidden="true">
                                    {{ item.name }}
                                </router-link>
                            </div>
                            <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
                                <DisclosureButton :class="[item.current ? 'bg-gray text-white' : 'text-white' , 'group w-full flex pl-2 py-2 text-base text-left font-medium rounded-md focus:outline-none']">
                                    <img :src="item.icon" :class="['mr-4 flex-shrink-0 h-6 w-6']" viewBox="0 0 22 22" aria-hidden="true">
                                    <span class="flex-1">
                                        {{ item.name }}
                                    </span>
                                    <svg :class="[open ? 'text-white rotate-90' : 'text-white', 'ml-3 h-6 w-6 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray']" viewBox="0 0 22 22" aria-hidden="true">
                                        <path d="M6 6L14 10L6 14V6Z" fill="white" />
                                    </svg>
                                </DisclosureButton>
                                <DisclosurePanel class="space-y-1 -mx-2 bg-dark-gunmetal-850 transition ease-in shadow-inner">
                                    <DisclosureButton v-for="subItem in item.children" :key="subItem.name" as="a" :href="subItem.href" class="group flex w-full items-center rounded-md py-2 pl-14 pr-2 text-base font-medium text-white hover:bg-gray hover:text-white">{{ subItem.name }}</DisclosureButton>
                                </DisclosurePanel>
                            </Disclosure>
                        </template>
                    </nav>
                    </div>
                </div>
            </div>
            <!-- <a class="text-white group flex items-center p-2 text-base font-medium rounded-md" @click="logout">Logout</a> -->
            
            <!-- Main Content -->
            <div class="bg-white text-black flex flex-1 flex-col overflow-y-auto md:pl-64">

                <!-- Mobile Header -->
                <div class="relative flex h-16 items-center justify-between bg-gray-100 md:hidden">
                    <!-- Offcanvas Menu Toggle-->
                    <button type="button" class="h-12 w-12 inline-flex items-center justify-center rounded-md md:hidden" @click="sidebarOpen = true">
                        <svg class="h-6 w-6" x-description="Heroicon name: outline/menu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                    <!-- Mobile Logo -->
                    <div class="flex flex-1 justify-center">
                        <div class="w-full px-2 text-center">
                            <h1 class="font-bold">ContrivIO</h1>
                        </div>
                    </div>
                    <!-- Spacer -->
                    <div class="h-12 w-12 inline-flex items-center justify-center rounded-md md:hidden"></div>
                </div>
                <router-view/>
            </div>
        </div>
        <router-view v-else/>
    </div>
</template>

<script>
// Added for offcanvas menu
import { ref } from 'vue'
import { Dialog, TransitionChild, TransitionRoot, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import DisclosureStateEmitter from "./components/DisclosureStateEmitter";
import ContrivIOIcon from '../images/nav-icons/contrivio-icon.svg';
import ManufacturersIcon from '../images/nav-icons/manufacturer-icon.svg';
import LabsIcon from '../images/nav-icons/lab-icon.svg';
import MaterialsIcon from '../images/nav-icons/material-icon.svg';
import SettingsIcon from '../images/nav-icons/settings-icon.svg';
import LogoutIcon from '../images/nav-icons/logout-icon.svg';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import { getAdminPath } from "./helper";

const sidebarOpen = ref(false);
const admin_path = getAdminPath();

const navigation = [
    {
        name: 'Dashboard',
        icon: ContrivIOIcon,
        current: true,
        href: admin_path+'/dashboard'
    },
    {
        name: 'Manufacturers', 
        icon: ManufacturersIcon, 
        current: false,
        href: admin_path+'/manufacturers'
    },
    {
      name: 'Labs',
      icon: MaterialsIcon,
      current: false,
      href: admin_path+'/labs'
    },
    {
        name: 'Test Results',
        icon: LabsIcon, 
        current: false,
        href: admin_path+'/results'
    },
    {
        name: 'Settings', 
        icon: SettingsIcon, 
        current: false,
        children: [
            { name: 'Analysis', href: admin_path+'/analysis'},
            { name: 'Measures', href: admin_path+'/measure'},
            { name: 'General', href: admin_path+'/general'},
            { name: 'Users', href: admin_path+'/users'},
        ],
    },
    {
        name: 'Logout',
        icon: LogoutIcon,
        current: false,
        href: admin_path+'/logout'
    },
]

// Close sibling menu items
const elements = ref([]);
const hideOther = (id) => {
    const items = elements.value.filter((elm) => {
        return elm.getAttribute("data-id") !== id.toString();
    });
    items.forEach((elm) => elm.click());
};
const doClose = (close) => {
    close();
}

export default {
    name: "App",
    // Components added for offcanvas menu
    components: {
        Dialog,
        TransitionChild,
        TransitionRoot,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        DisclosureStateEmitter,
        ConfirmDialog,
        Toast
    },
    data() {
        return {
            isLoggedIn: false,
            sidebarOpen, // Added for mobile offcanvas menu
            elements,
            hideOther,
            doClose
        }
    },
    created() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true
        }
        this.sidebarOpen = sidebarOpen // Added for mobile offcanvas menu
        this.navigation_menu = navigation
        this.elements = elements
        this.hideOther = hideOther
        this.doClose = doClose
    },
    methods: {
        logout(e,name) {
          if(name == 'Logout'){
            e.preventDefault()

              this.$axios.post('/api/logout')
                  .then(response => {
                    if (response.data.success) {
                      window.location.href = admin_path
                    } else {
                      console.log(response)
                    }
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
          }

        },
      nothing(){
      }
    },
}
</script>
