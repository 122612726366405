<template>
    <update-manufacturer></update-manufacturer>
</template>

<script>
import UpdateManufacturer from './Update'
export default {
    name: "CreateManufacturer",
    components: {
        UpdateManufacturer
    }
}
</script>

<style scoped>

</style>