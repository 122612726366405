<template>
  <PageTitle :title="`Measure - ${id?'Edit':'New'}`" buttonGroup="Save" @save="submit"/>
  <div class="w-full max-w-md px-2 py-8 sm:px-6">
    <div class="md:flex md:space-x-4 lg:pb-8">
      <div class="mt-6 grid grid-cols-12 gap-4 md:w-1/2">
        <div class="relative col-span-12 sm:col-span-6">
          <select v-model="object.type" id="type">
            <option v-for="data_type in data_types" :value="data_type">{{data_type}}</option>
          </select>
          <label for="type" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Type</label>

          <small v-if="(v$.object.type.$invalid && submitted) || v$.object.type.$pending.$response" class="p-error">{{v$.object.type.required.$message.replace('Value', 'Type')}}</small>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.name"
              id="name"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="name" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Name</label>
          <small v-if="(v$.object.name.$invalid && submitted) || v$.object.name.$pending.$response" class="p-error">{{v$.object.name.required.$message.replace('Value', 'Name')}}</small>
        </div>
      </div>
    </div>

  </div>
</template>

<script>



import PageTitle from "../../components/PageTitle.vue"
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { redirectTimeout,getDataTypes } from "../../helper";

const data_types = getDataTypes();


export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        PageTitle
    },
    name: "UpdateMeasure",
    data() {
        return {
            componentList: "Measure",
            componentCreate: "CreateMeasure",
            id: this.$route.params.id || false,
            submitted: false,
            end_point:"measure",
            data_types:[],
            object:{
                name:"",
                type:"",
                source:"",
            },
        }
    },
    created() {
    },
    mounted() {
        this.fetchData();
    },
    validations() {
        return {
            object:{
                name: {
                    required
                },
                type: {
                    required
                },
                source:""
            }

        }
    },
    methods: {
        async submit(is_continue) {
            this.submitted = true;

            const isFormValid = await this.v$.$validate();

            if (!isFormValid) {
                return false;
            }

            if(this.id){
                this.update(is_continue);
            }else{
                this.create(is_continue);
            }

            return false;

        },
        create(is_continue){

                this.$axios.post(`/api/${this.end_point}`, this.object).then(response => {
                    const result = response.data;
                    if(result.message){
                        this.$toast.add({
                            severity:result.success?'success':'warn',
                            summary: 'Notice',
                            detail:result.message,
                            life: 5000,
                            group: 'br'
                        });
                    }
                    if(!result.success){
                        return false;
                    }
                  if(is_continue == 2){
                    return true;
                  }
                    if(!is_continue){
                        this.$router.push({name: this.componentList})
                    }else{
                        this.$router.push({name: this.componentCreate})
                    }

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        update(is_continue){

                this.$axios.put(`/api/${this.end_point}/${this.id}`, this.object).then(response => {
                    const result = response.data;
                    console.log(result);
                    if(result.message){
                        this.$toast.add({
                            severity:result.success?'success':'warn',
                            summary: 'Notice',
                            detail:result.message,
                            life: 5000,
                            group: 'br'
                        });
                    }
                    if(!result.success){
                        return false;
                    }
                  if(is_continue == 2){
                    return true;
                  }
                    if(!is_continue){
                        this.$router.push({name: this.componentList})
                    }else{
                        this.$router.push({name: this.componentCreate})
                    }

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        showErrorMessage(message){
            this.$toast.add({
                severity:'warn',
                summary: 'Error',
                detail:message,
                life: 5000,
                group: 'br'
            });
        },
        fetchData(){
            this.data_types = data_types;
            this.fetchObject();
        },
        fetchObject(){
            if(!this.id){
                return false;
            }


                this.$axios.get(`/api/${this.end_point}/${this.id}`).then(response => {
                    const result = response.data;
                    if(!result.success){
                        if(result.message){
                            this.showErrorMessage(result.message);
                        }
                        return false;
                    }
                    this.object = result.data;

                })
                    .catch(error => {
                      if(error.response.status === 401) {
                        redirectTimeout()
                      }
                    });
        },

    },
  beforeRouteEnter(to, from, next) {
    if (!window.Laravel.isLoggedin) {
      redirectTimeout();
    }
    next();
  }
}
</script>
