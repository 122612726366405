<template>
  <!--Header-->
  <LookupHeader />
  <!--Body-->
  <div class="relative pt-8 bg-gradient-to-b from-[#89f7fe] to-[#66a6ff]">
    <div class="mx-auto pb-12 xl:pb-24">
      <div class="mb-10 mx-auto max-w-[1300px] px-12 relative z-20">
        <div class="flex flex-wrap md:block md:columns-2 md:gap-4 xl:gap-7">
          <div class="basis-full order-1">
            <p
              class="font-medium mb-7 text-dark-blue text-xl text-justify text-last-center md:text-last-left xl:text-4xl">
              Please see the following results for {{ object.name }} with lot number {{ object.lot_number }}. Test results
              confirm that this lot meets all label claims.</p>
            <p
              class="block font-medium mx-auto text-white text-xl bg-[#79d03d] leading-4 mb-9 py-3.5 px-6 rounded-md text-center uppercase md:hidden">
              PASS</p>
          </div>
          <div class="basis-full mb-9 order-3 md:break-after-right">
            <div class="items-center mb-6 md:flex md:justify-between xl:mb-4">
              <h2 class="font-medium text-center text-dark-blue text-xl uppercase md:text-3xl xl:text-5xl">Test <span
                  class="font-bold">Results:</span></h2>
              <p
                class="font-medium text-white text-3xl bg-[#79d03d] leading-none py-3.5 px-6 rounded-md w-fit uppercase hidden md:block xl:text-5xl">
                Pass</p>
            </div>
            <table class="leading-none overflow-hidden text-dark-blue text-sm w-full">
              <thead>
                <tr>
                  <th class="sr-only">Product Information</th>
                  <th class="sr-only">Product Detail</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="bg-white border border-solid border-dark-blue flex justify-between mb-1 rounded-md px-3.5 py-2.5 w-full xl:text-2xl xl:mb-2">
                  <td class="font-bold">Product Name</td>
                  <td class="text-right">{{ object.name }}</td>
                </tr>
                <tr
                  class="bg-white border border-solid border-dark-blue flex justify-between mb-1 rounded-md px-3.5 py-2.5 w-full xl:text-2xl xl:mb-2">
                  <td class="font-bold">Flavor</td>
                  <td class="text-right">{{ object.flavor }}</td>
                </tr>
                <tr
                  class="bg-white border border-solid border-dark-blue flex justify-between mb-1 rounded-md px-3.5 py-2.5 w-full xl:text-2xl xl:mb-2">
                  <td class="font-bold">Lot Number</td>
                  <td class="text-right">{{ object.lot_number }}</td>
                </tr>
                <tr
                  class="bg-white border border-solid border-dark-blue flex justify-between mb-1 rounded-md px-3.5 py-2.5 w-full xl:text-2xl xl:mb-2">
                  <td class="font-bold">Sublot(s)</td>
                  <td class="text-right">{{ object.sublot }}</td>
                </tr>
                <tr
                  class="bg-white border border-solid border-dark-blue flex justify-between mb-1 rounded-md px-3.5 py-2.5 w-full xl:text-2xl xl:mb-2">
                  <td class="font-bold">Date of Manufacturing</td>
                  <td class="text-right">{{ momentDate(object.manufacturing_date) }}</td>
                </tr>
                <tr
                  class="bg-white border border-solid border-dark-blue flex justify-between mb-1 rounded-md px-3.5 py-2.5 w-full xl:text-2xl xl:mb-2">
                  <td class="font-bold">Shelf Life</td>
                  <td class="text-right">{{ object.shelf_life }} {{ object.life_uom }}</td>
                </tr>
                <tr
                  class="bg-white border border-solid border-dark-blue flex justify-between mb-1 rounded-md px-3.5 py-2.5 w-full xl:text-2xl xl:mb-2">
                  <td class="font-bold">Expiration</td>
                  <td class="text-right">{{ momentDate(object.lot_expiration) }}</td>
                </tr>
                <tr
                  class="bg-white border border-solid border-dark-blue flex justify-between rounded-md px-3.5 py-2.5 w-full xl:text-2xl">
                  <td class="font-bold">Serving Size</td>
                  <td class="text-right">{{ object.serving_size }} {{ object.serving_uom }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="basis-full mb-8 order-2 md:mb-0 xl:pt-48">
            <div class="mb-5 xl:mb-16">
              <img class="mx-auto" width="256" height="513"
                :src="object.image_src ? object.image_src : 'https://place-hold.it/256x513/666/fff/000'" alt="">
            </div>
            <div class="grid grid-cols-4 gap-6">
              <div v-for="obj in object.images" class="aspect-square">
                <img class="h-full w-full object-cover object-top" style="cursor: pointer"
                  @click="viewImage(obj.image_src)"
                  :src="obj.image_src ? obj.image_src : 'https://place-hold.it/300x300/666/fff/000'" alt="">
              </div>
            </div>
          </div>
        </div>
        <h2
          class="bg-dark-blue font-bold leading-none mb-1.5 py-3.5 rounded-md text-center text-sm text-white uppercase md:mb-0 md:mt-8 xl:mb-1 xl:rounded-lg xl:text-2xl xl:py-4">
          Physical Testing</h2>
        <table
          class="leading-none mb-9 text-sm w-full md:table-fixed md:border-separate md:border-spacing-2 md:text-center md:border-hidden md:-mx-2 md:w-[calc(100%+1rem)]">
          <thead class="hidden md:table-header-group">
            <tr>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Analysis</th>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Specification
              </th>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Result</th>
            </tr>
          </thead>
          <tbody class="text-dark-blue" v-for="obj in object.results">
            <tr v-if="obj.test_type == 'Physical'">
              <td
                class="bg-white block border border-solid border-dark-blue mb-1.5 rounded-md w-full px-4 py-2.5 md:table-cell xl:text-2xl">
                <span class="font-bold md:hidden">Analysis:</span> {{ obj.analysis }}
                <dl class="pt-4 lg:hidden">
                  <dt class="font-bold inline mr-1">Specification:</dt>
                  <dd class="inline after:block after:content-[''] after:mb-4">{{ obj.max_specification }}</dd>
                  <dt class="font-bold inline mr-1">Result:</dt>
                  <dd class="inline after:block after:content-['']">{{ obj.result }}</dd>
                </dl>
              </td>
              <td
                class="hidden md:table-cell md:bg-white md:border md:border-solid md:border-dark-blue md:mb-1.5 md:rounded-md md:px-4 md:py-2.5 xl:text-2xl">
                {{ obj.max_specification }}</td>
              <td
                class="hidden md:table-cell md:bg-white md:border md:border-solid md:border-dark-blue md:mb-1.5 md:rounded-md md:px-4 md:py-2.5 xl:text-2xl">
                {{ obj.result }}</td>
            </tr>
          </tbody>
        </table>

        <h2
          class="bg-dark-blue font-bold leading-none mb-1.5 py-3.5 rounded-md text-center text-sm text-white uppercase md:mb-0 md:mt-8 xl:mb-1 xl:rounded-lg xl:text-2xl xl:py-4">
          Chemical Testing</h2>
        <table
          class="leading-none mb-9 text-sm w-full md:table-fixed md:border-separate md:border-spacing-2 md:text-center md:border-hidden md:-mx-2 md:w-[calc(100%+1rem)]">
          <thead class="hidden md:table-header-group">
            <tr>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Analysis</th>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Specification
              </th>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Result</th>
            </tr>
          </thead>
          <tbody class="text-dark-blue" v-for="obj in object.results">
            <tr v-if="obj.test_type == 'Chemical'">
              <td
                class="bg-white block border border-solid border-dark-blue mb-1.5 rounded-md w-full px-4 py-2.5 md:table-cell xl:text-2xl">
                <span class="font-bold md:hidden">Analysis:</span> {{ obj.analysis }}
                <dl class="pt-4 lg:hidden">
                  <dt class="font-bold inline mr-1">Specification:</dt>
                  <dd class="inline after:block after:content-[''] after:mb-4" v-if="obj.min_specification <= 0">{{
                    obj.max_spec_operator }} {{ obj.max_specification }} {{ obj.specification_uom }}</dd>
                  <dd class="inline after:block after:content-[''] after:mb-4" v-if="obj.min_specification > 0">{{
                    obj.min_specification }} - {{ obj.max_specification }} {{ obj.specification_uom }}</dd>
                  <dt class="font-bold inline mr-1">Result:</dt>
                  <dd class="inline after:block after:content-['']">{{ obj.result_operator != '=' ?
                    obj.result_operator : '' }}{{ obj.result }} {{ obj.result_uom }}</dd>
                </dl>
              </td>
              <td
                class="hidden md:table-cell md:bg-white md:border md:border-solid md:border-dark-blue md:mb-1.5 md:rounded-md md:px-4 md:py-2.5 xl:text-2xl"
                v-if="obj.min_specification <= 0">{{ obj.max_spec_operator }} {{ obj.max_specification }}
                {{ obj.specification_uom }}</td>
              <td
                class="hidden md:table-cell md:bg-white md:border md:border-solid md:border-dark-blue md:mb-1.5 md:rounded-md md:px-4 md:py-2.5 xl:text-2xl"
                v-if="obj.min_specification > 0">{{ obj.min_specification }} - {{ obj.max_specification }}
                {{ obj.specification_uom }}</td>
              <td
                class="hidden md:table-cell md:bg-white md:border md:border-solid md:border-dark-blue md:mb-1.5 md:rounded-md md:px-4 md:py-2.5 xl:text-2xl">
                {{ obj.result_operator != '=' ? obj.result_operator : '' }}{{ obj.result }} {{ obj.result_uom }}</td>
            </tr>
          </tbody>
        </table>

        <h2
          class="bg-dark-blue font-bold leading-none mb-1.5 py-3.5 rounded-md text-center text-sm text-white uppercase md:mb-0 md:mt-8 xl:mb-1 xl:rounded-lg xl:text-2xl xl:py-4">
          Microbial Testing</h2>
        <table
          class="leading-none mb-9 text-sm w-full md:table-fixed md:border-separate md:border-spacing-2 md:text-center md:border-hidden md:-mx-2 md:w-[calc(100%+1rem)]">
          <thead class="hidden md:table-header-group">
            <tr>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Analysis</th>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Specification
              </th>
              <th class="bg-dark-blue py-5 rounded-md text-sm text-white xl:py-6 xl:rounded-lg xl:text-2xl">Result</th>
            </tr>
          </thead>
          <tbody class="text-dark-blue" v-for="obj in object.results">
            <tr v-if="obj.test_type == 'Microbial'">
              <td
                class="bg-white block border border-solid border-dark-blue mb-1.5 rounded-md w-full px-4 py-2.5 md:table-cell xl:text-2xl">
                <span class="font-bold md:hidden">Analysis:</span> {{ obj.analysis }}
                <dl class="pt-4 lg:hidden">
                  <dt class="font-bold inline mr-1">Specification:</dt>
                  <dd class="inline after:block after:content-[''] after:mb-4" v-if="obj.min_specification <= 0">{{
                    obj.max_spec_operator }} {{ obj.max_specification }} {{ obj.specification_uom }}</dd>
                  <dd class="inline after:block after:content-[''] after:mb-4" v-if="obj.min_specification > 0">{{
                    obj.min_specification }} - {{ obj.max_specification }} {{ obj.specification_uom }}</dd>
                  <dt class="font-bold inline mr-1">Result:</dt>
                  <dd class="inline after:block after:content-['']">{{ obj.result_operator != '=' ? obj.result_operator :
                    '' }}{{ obj.result }} {{ obj.result_uom }}</dd>
                </dl>
              </td>
              <td
                class="hidden md:table-cell md:bg-white md:border md:border-solid md:border-dark-blue md:mb-1.5 md:rounded-md md:px-4 md:py-2.5 xl:text-2xl"
                v-if="obj.min_specification <= 0">{{ obj.max_spec_operator }} {{ obj.max_specification }}
                {{ obj.specification_uom }}</td>
              <td
                class="hidden md:table-cell md:bg-white md:border md:border-solid md:border-dark-blue md:mb-1.5 md:rounded-md md:px-4 md:py-2.5 xl:text-2xl"
                v-if="obj.min_specification > 0">{{ obj.min_specification }} - {{ obj.max_specification }}
                {{ obj.specification_uom }}</td>
              <td
                class="hidden md:table-cell md:bg-white md:border md:border-solid md:border-dark-blue md:mb-1.5 md:rounded-md md:px-4 md:py-2.5 xl:text-2xl">
                {{ obj.result_operator != '=' ? obj.result_operator : '' }}{{ obj.result }} {{ obj.result_uom }}</td>
            </tr>
          </tbody>
        </table>
        <p class="mb-4 text-center text-white xl:text-2xl xl:mb-8">All products are formulated in the USA and packaged in
          a cGMP certified facility in the USA. Ingredients and raw materials are sourced from all over the world.</p>
        <p class="text-center text-white xl:text-2xl">All products are lab-tested in an ISO 17025 certified facility.</p>
      </div>
      <div
        class="repeating-logo absolute opacity-5 mix-blend-multiply bg-[#0d3b8e] bg-cover bg-repeat content-[''] inset-0 h-full w-full z-10">
      </div>
    </div>
  </div>
  <!--Footer-->
  <LookupFooter />
</template>

<script>
import LookupHeader from '../components/LookupHeader'
import LookupFooter from '../components/LookupFooter'
import moment from 'moment';

export default {
    name: "TestResultPage",
    components: {
      LookupHeader,
      LookupFooter
    },
    data() {
      return {
        end_point:'test-result',
        upc: this.$route.params.upc || '',
        lot_id: this.$route.params.lot_id || '',
        id: this.$route.params.id || '',
        object:[],
      }
    },
    created() {

  },
  mounted() {
    this.fetchData();
  },
  methods: {
    momentDate(date){
      if(!date){
        return '-';
      }
      return moment(date).format("MMMM DD, YYYY");
    },
    viewImage(image_src) {
      this.object.image_src = image_src;
    },
      fetchData(){
        this.$axios.get(`/api/${this.end_point}/${this.upc}/${this.lot_id}/${this.id}`).then(response => {
          const result = response.data;
          if(!result.success){
            if(result.message){
              this.showErrorMessage(result.message);
            }
            this.$router.push({name:'TestResultLookup', params:{}});
          }else{
            this.object = result.data;
          }

      }).catch(error => {
        console.error(error);
      });
    },
    showErrorMessage(message){
      this.$toast.add({
        severity:'warn',
        summary: 'Error',
        detail:message,
        life: 5000,
        group: 'br'
      });
    },
  }
}
</script>
<style>
html,
body,
#app {
  overflow-x: hidden;
}
</style>
