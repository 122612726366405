<template>
  <PageTitle :title="`User - ${id?'Edit':'New'}`" buttonGroup="Save" buttonCancel="Cancel" @save="submit" @back="back"/>
  <div class="w-full max-w-md px-2 py-8 sm:px-6">
    <div class="md:flex md:space-x-4 lg:pb-8">
      <div class="mt-6 grid grid-cols-12 gap-4 md:w-1/2">
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.user_name"
              id="user_name"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="user_name" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">User Name</label>
          <small v-if="(v$.object.user_name.$invalid && submitted) || v$.object.user_name.$pending.$response" class="p-error">{{v$.object.user_name.required.$message.replace('Value', 'User Name')}}</small>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.password"
              id="password"
              type="password"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="password" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Password</label>
          <small v-if="requiredPass()" class="p-error">Password required</small>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.f_name"
              id="f_name"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="f_name" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">First Name</label>
          <small v-if="(v$.object.f_name.$invalid && submitted) || v$.object.f_name.$pending.$response" class="p-error">{{v$.object.f_name.required.$message.replace('Value', 'First Name')}}</small>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.l_name"
              id="l_name"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="l_name" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Last Name</label>
          <small v-if="(v$.object.l_name.$invalid && submitted) || v$.object.l_name.$pending.$response" class="p-error">{{v$.object.l_name.required.$message.replace('Value', 'Last Name')}}</small>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.address"
              id="address"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              />
          <label for="address" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Address</label>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.address2"
              id="address2"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              />
          <label for="address2" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Address 2</label>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.city"
              id="city"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              />
          <label for="city" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">City</label>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.state"
              id="state"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              />
          <label for="state" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">State</label>
          </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.zip_code"
              id="zip_code"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
               />
          <label for="zip_code" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Zip Code</label>
          </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.country"
              id="country"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              />
          <label for="country" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Country</label>
          </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.phone"
              id="phone"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="phone" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Phone</label>
          <small v-if="(v$.object.phone.$invalid && submitted) || v$.object.phone.$pending.$response" class="p-error">{{v$.object.phone.required.$message.replace('Value', 'Phone')}}</small>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.email"
              id="email"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required=""/>
          <label for="email" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Email</label>
          <small v-if="(v$.object.email.$invalid && submitted) || v$.object.email.$pending.$response" class="p-error">{{v$.object.email.required.$message.replace('Value', 'Email')}}</small>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import PageTitle from "../PageTitle.vue"
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { redirectTimeout } from "../../helper";

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        PageTitle
    },
    name: "UpdateUser",
    data() {
        return {
          componentList: "Users",
          componentCreate: "CreateUser",
          id:this.$route.params.id || false,
          object: {
              id:this.$route.params.id || 0,
              address:'',
              address2:'',
              city:'',
              country:'',
              email:'',
              f_name:'',
              image_id:0,
              company_id:0,
              l_name:'',
              name:'',
              phone:'',
              role_id:'',
              state:'',
              user_name:'',
              zip_code:'',
              password:'',
            }
        }
    },
  mounted() {
    this.fetchData();
  },
    validations() {
      return {
        object:{
          user_name: {
            required
          },
          f_name: {
            required
          },
          l_name: {
            required
          },
          phone: {
            required
          },
          email: {
            required
          },
        }

      }
    },
    methods: {
      back(){
        this.$router.push({name:'Users', params:{}});
      },
      async submit(is_continue) {
        this.submitted = true;

        const isFormValid = await this.v$.$validate();

        if (!isFormValid) {
          return false;
        }

        this.save(is_continue)

        return false;

      },
      showErrorMessage(message){
        this.$toast.add({
          severity:'warn',
          summary: 'Error',
          detail:message,
          life: 5000,
          group: 'br'
        });
      },
      requiredPass(){
        if(this.id)
          return false;
        if(this.submitted && !this.id && !this.object.password)
          return true;
      },
      fetchData(){
        this.fetchObject();
      },
      fetchObject() {
        if (!this.id) {
          return false;
        }
        this.$axios.get(`/api/users/edit/${this.$route.params.id}`)
            .then(response => {
              const result = response.data;
              if(!result.success){
                if(result.message){
                  this.showErrorMessage(result.message);
                }
                return false;
              }
              this.object = result.data;
            })
            .catch(function (error) {
              if(error && error.response.status === 401) {
                redirectTimeout()
              }
            });
      },
      save(is_continue) {
          this.$axios.post(`/api/users/save/${this.object.id}`, this.object)
              .then(response => {
                const result = response.data;
                if(result.message){
                  this.$toast.add({
                    severity:result.success?'success':'warn',
                    summary: 'Notice',
                    detail:result.message,
                    life: 5000,
                    group: 'br'
                  });
                }
                if(!result.success){
                  return false;
                }
                if(is_continue == 2){
                  return true;
                }
                if(!is_continue){
                  this.$router.push({name: this.componentList})
                }else{
                  this.$router.push({name: this.componentCreate})
                }
              })
              .catch(function (error) {
                if(error && error.response.status === 401) {
                  redirectTimeout()
                }
              });
      }
    },
  beforeRouteEnter(to, from, next) {
    if (!window.Laravel.isLoggedin) {
      redirectTimeout();
    }
    next();
  }
}
</script>
