import {createApp} from 'vue'
import Pagination from 'v-pagination-3';
import { VueReCaptcha } from "vue-recaptcha-v3";
import App from './App.vue'
import axios from 'axios'
import router from './router'
import ConfirmationService from 'primevue/confirmationservice';
import PrimeVue from "primevue/config";
import ToastService from 'primevue/toastservice';

const app = createApp(App)
app.config.globalProperties.$axios = axios;
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(VueReCaptcha, { siteKey: window.Laravel.site_key ,loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true
    }});
app.component('pagination', Pagination);
app.mount('#app');

