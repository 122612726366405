<template>
    <PageTitle title="Manufacturers" buttonGroup="Create" routeLink="/manufacturer/create"/>

    <div class="flex flex-col">
        <!-- Manufacturer List -->
        <div class="px-4 sm:px-6 lg:px-8">
            <div class="-mx-4 mt-8 overflow-hidden sm:-mx-6 md:mx-0 md:rounded-lg">

                <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">No.</th>
                            <th scope="col" class="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">Name</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Address</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Address 2</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">City</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">State</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Zip Code</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:text-center">Phone</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 text-right text-sm font-semibold sm:pr-6">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="object in objects">
                            <td class="py-3.5 pl-4 pr-3 text-left align-top text-sm font-semibold text-gray-900 sm:pl-6">{{ object.code }}</td>
                            <td class="hidden px-3 py-3.5 text-center text-sm text-gray-900 lg:table-cell">
                                {{ object.name }}
                                <dl class="font-normal lg:hidden">
                                    <dt class="sr-only">Address 2</dt>
                                    <dt class="mt-1 truncate text-gray-700">{{ object.address2 }}</dt>
                                    <dt class="sr-only">City, State, Zip Code</dt>
                                    <dt class="mt-1 truncate text-gray-700">{{ object.city }}, {{ object.state }} {{ object.zip_code }}</dt>
                                </dl>
                            </td>
                            <td class="hidden px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">{{ object.address }}</td>
                            <td class="hidden px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">{{ object.address2 }}</td>
                            <td class="hidden px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">{{ object.city }}</td>
                            <td class="hidden px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">{{ object.state }}</td>
                            <td class="hidden px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">{{ object.zip_code }}</td>
                            <td class="hidden px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">{{ object.phone }}</td>
                            <td class="px-3 py-4 text-center text-sm text-gray-500 lg:table-cell">
                                <router-link :to="{ name: 'UpdateManufacturer', params: { id: object.id }}">
                                    <span class="fa fa-edit"></span>
                                </router-link>
                                <a href="#" class="p-1" @click="deleteObject(object.id)"><span class="fa fa-times"></span></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Paginator :rows="per_page" :totalRecords="total" @page="onPage($event)"></Paginator>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle.vue"
import Paginator from "primevue/paginator";
import { redirectTimeout } from "../helper";

export default {
    name: "Manufacturers",
    components: {
        PageTitle,
        Paginator
    },
    data() {
        return {
            end_point:"manufacturer",
            errors:{},
            objects: [],
            per_page:10,
            total:0,
            query:{
                page:1
            }
        }
    },
    created() {

    },
    mounted() {
        this.fetchData();
    },
    methods: {
        onPage($event){
            let query = this.query;
            query['page'] = $event.page + 1;
            this.fetchData(query);
        },
        fetchData(query = null) {
          query = query || this.query;
          this.$axios.get(`/api/${this.end_point}`, {params:query}).then(response => {
            const result = response.data || {};
            this.objects = result.data.data || [];
            this.per_page   = result.data.per_page;
            this.total      = result.data.total;

          }).catch(error => {
            if(error.response.status === 401) {
              redirectTimeout()
            }
          });


        },
        deleteObject(id){
            this.$confirm.require({
                message: 'Are you sure you want to proceed?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {
                  this.$axios.delete(`/api/${this.end_point}/${id}`).then(response => {
                    const result = response.data;
                    if(result.message){
                      this.$toast.add({
                        severity:result.success?'success':'error',
                        summary: 'Notice',
                        detail:result.message,
                        life: 5000
                      });
                    }

                    if(result.success){
                      this.fetchData();
                    }

                  }).catch(error => {
                    if(error.response.status === 401) {
                      redirectTimeout()
                    }
                  });

                },
                reject: () => {
                }
            });

            return false;
        },
    },
  beforeRouteEnter(to, from, next) {
    if (!window.Laravel.isLoggedin) {
      redirectTimeout()
    }
    next();
  }
}
</script>
