<template>
    <update-general></update-general>
</template>

<script>
import UpdateGeneral from './Update'
export default {
    name: "CreateGeneral",
    components: {
      UpdateGeneral
    }
}
</script>

<style scoped>

</style>