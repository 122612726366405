<template>
  <PageTitle :title="`General - ${id?'Edit':'New'}`" buttonGroup="Save" buttonCancel="Cancel" @save="submit" @back="back"/>
  <div class="w-full max-w-md px-2 py-8 sm:px-6">
    <div class="md:flex md:space-x-4 lg:pb-8">
      <div class="mt-6 grid grid-cols-12 gap-4 md:w-1/2">
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.key"
              id="key"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="key" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Key</label>
          <small v-if="(v$.object.key.$invalid && submitted) || v$.object.key.$pending.$response" class="p-error">{{v$.object.key.required.$message.replace('Value', 'Key')}}</small>
        </div>
        <div class="relative col-span-12 sm:col-span-6">
          <input
              v-model="object.value"
              id="value"
              type="text"
              class="peer mt-1 block w-full rounded-sm py-2 px-3 shadow-sm focus:border-dark-gunmetal focus:outline-none focus:ring-dark-gunmetal sm:text-sm"
              required="" />
          <label for="value" class="absolute px-1 left-2 -top-1.5 text-sm bg-white">Value</label>
          <small v-if="(v$.object.value.$invalid && submitted) || v$.object.value.$pending.$response" class="p-error">{{v$.object.value.required.$message.replace('Value', 'Value')}}</small>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full max-w-md px-2 py-8 sm:px-6">
    <p>Noted:</p>
    <p>Key = session_timeout : Will timeout in minutes</p>
  </div>
</template>

<script>



import PageTitle from "../../components/PageTitle.vue"
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { redirectTimeout } from "../../helper";



export default {
    setup: () => ({ v$: useVuelidate() }),
    components: {
        PageTitle
    },
    name: "UpdateGeneral",
    data() {
        return {
            componentList: "General",
            componentCreate: "CreateGeneral",
            id: this.$route.params.id || false,
            submitted: false,
            end_point:"general",
            data_types:[],
            object:{
                name:"",
                type:"",
            },
        }
    },
    mounted() {
        this.fetchData();
    },
    validations() {
        return {
            object:{
                key: {
                    required
                },
                value: {
                    required
                }
            }

        }
    },
    methods: {
      back(){
        this.$router.push({name:'General', params:{}});
      },
        async submit(is_continue) {
            this.submitted = true;

            const isFormValid = await this.v$.$validate();

            if (!isFormValid) {
                return false;
            }

            if(this.id){
                this.update(is_continue);
            }else{
                this.create(is_continue);
            }

            return false;

        },
        create(is_continue){

                this.$axios.post(`/api/${this.end_point}`, this.object).then(response => {
                    const result = response.data;
                    if(result.message){
                        this.$toast.add({
                            severity:result.success?'success':'warn',
                            summary: 'Notice',
                            detail:result.message,
                            life: 5000,
                            group: 'br'
                        });
                    }
                    if(!result.success){
                        return false;
                    }
                  if(is_continue == 2){
                    return true;
                  }
                    if(!is_continue){
                        this.$router.push({name: this.componentList})
                    }else{
                        this.$router.push({name: this.componentCreate})
                    }

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        update(is_continue){

                this.$axios.put(`/api/${this.end_point}/${this.id}`, this.object).then(response => {
                    const result = response.data;
                    if(result.message){
                        this.$toast.add({
                            severity:result.success?'success':'warn',
                            summary: 'Notice',
                            detail:result.message,
                            life: 5000,
                            group: 'br'
                        });
                    }
                    if(!result.success){
                        return false;
                    }
                  if(is_continue == 2){
                    return true;
                  }
                    if(!is_continue){
                        this.$router.push({name: this.componentList})
                    }else{
                        this.$router.push({name: this.componentCreate})
                    }

                }).catch(error => {
                  if(error.response.status === 401) {
                    redirectTimeout()
                  }
                });
        },
        showErrorMessage(message){
            this.$toast.add({
                severity:'warn',
                summary: 'Error',
                detail:message,
                life: 5000,
                group: 'br'
            });
        },
        fetchData(){
            this.fetchObject();
        },
        fetchObject(){
            if(!this.id){
                return false;
            }
          this.$axios.get(`/api/${this.end_point}/${this.id}`).then(response => {
            const result = response.data;
            if(!result.success){
              if(result.message){
                this.showErrorMessage(result.message);
              }
              return false;
            }
            this.object = result.data;

          })
              .catch(error => {
                if(error.response.status === 401) {
                  redirectTimeout()
                }
              });
        },

    },
  beforeRouteEnter(to, from, next) {
    if (!window.Laravel.isLoggedin) {
      redirectTimeout();
    }
    next();
  }
}
</script>
