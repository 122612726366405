<template>
    <update-measure></update-measure>
</template>

<script>
import UpdateMeasure from './Update'
export default {
    name: "CreateMeasure",
    components: {
      UpdateMeasure
    }
}
</script>

<style scoped>

</style>