<template>
    <footer class="bg-dark-blue px-12 py-16">
        <div class="md:flex md:flex-wrap lg:flex-nowrap">
            <img :src="this.logo" class="h-20 mb-8 mx-auto w-auto md:basis-1/2 lg:basis-1/4" alt="LEAN Hydration">
            <p class="mb-10 text-justify text-last-center text-opacity-80 text-sm text-white md:basis-1/2 lg:basis-1/4">Our
                mission is to support better health through
                staying lean and hydrated. Together, we can make Every Body Better!&trade;</p>
            <ul class="columns-2 mb-8 px-8 md:basis-1/2 lg:basis-1/4">
                <li v-for="item in navigation" :key="item.name"><a :href="item.href"
                        class="leading-8 text-opacity-80 text-sm text-white">{{
                            item.name }}</a></li>
            </ul>
            <div class="md:basis-1/2 lg:basis-1/4">
                <h3 class="font-bold mb-5 text-center text-sm text-white uppercase md:basis-1/2 lg:basis-1/4">Follow us at
                    #LEANHYDRATION</h3>
                <ul class="flex justify-center space-x-6">
                    <li class="border border-white rounded-full block h-9 w-9"><a class="flex h-full"
                            href="https://facebook.com/leanhydration"><svg class="fill-white m-auto"
                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                                <path
                                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                            </svg></a></li>
                    <li class="border border-white rounded-full block h-9 w-9"><a class="flex h-full"
                            href="https://instagram.com/leanhydration"><svg class="fill-white m-auto"
                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                <path
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                            </svg></a></li>
                    <li class="border border-white rounded-full block h-9 w-9"><a class="flex h-full"
                            href="https://tiktok.com/@leanhydration"><svg class="fill-white m-auto"
                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                <path
                                    d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                            </svg></a></li>
                </ul>
            </div>
        </div>
        <p class="mx-auto py-3 text-center text-opacity-80 text-white text-xs md:text-left lg:text-right">&copy; 2023,
            LEAN Hydration&trade;
        </p>
    </footer>
</template>

<script>
import Logo from '../../images/lookup/horizontal-logo-white.svg'


const navigationConst = [
    { name: 'Shop', href: 'https://leanhydration.com/products/lean-hydration%E2%84%A2-electrolyte-drink-mix-16-servings#' },
    { name: 'About', href: 'https://leanhydration.com/pages/who-we-are' },
    { name: 'LEAN Nation', href: 'https://leanhydration.com/pages/lean-community' },
    { name: 'Contact Us', href: 'https://leanhydration.com/pages/contact' },
    { name: 'Learn', href: 'https://leanhydration.com/pages/learn' },
    { name: 'Shipping Policy', href: 'https://leanhydration.com/policies/shipping-policy' },
    { name: 'Privacy Policy', href: 'https://leanhydration.com/policies/privacy-policy' },
    { name: 'Terms of Service', href: 'https://leanhydration.com/policies/terms-of-service' },
    { name: 'Refund Policy', href: 'https://leanhydration.com/policies/refund-policy' },
    { name: 'Collaboration', href: 'https://leanhydration.com/pages/collab' },
    { name: 'Become a Reseller', href: 'https://leanhydration.com/pages/reseller' },
]

const socialMedia = [
    {
        name: 'facebook',
        href: 'https://facebook.com/leanhydration',
        svg: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>'
    },
    {
        name: 'instagram',
        href: 'https://instagram.com/leanhydration',
        svg: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>'
    },
    {
        name: 'tiktok',
        href: 'https://tiktok.com/@leanhydration',
        svg: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg>'
    }
]

export default {
    name: "Lookup Footer",
    data() {
        return {
            logo: '',
            navigation: [],
            socialMedia: []
        }
    },
    created() {
        this.logo = Logo
        this.navigation = navigationConst
        this.socialMedia = socialMedia
    },
    methods: {
    }
}
</script>
