<template>
  <update-user></update-user>
</template>

<script>
import UpdateUser from "./Update";
export default {
  name: "CreateUser",
  components: {
    UpdateUser
  }
}
</script>

<style scoped>

</style>