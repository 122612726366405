<template>
    <div class="border-b p-4 flex items-center justify-between sticky top-0 z-10 bg-white">
        <div class="flex-1 min-w-0">
            <h1 class="text-3xl font-bold leading-7 sm:text-4xl sm:tracking-tight sm:truncate">
                {{ title }}
            </h1>
        </div>

        <TitleButton v-if="buttonGroup" :buttonDuplicate="buttonDuplicate" :buttonType="buttonGroup" :buttonCancel="buttonCancel" :routeLink="routeLink" @duplicate="duplicate" @back="back" @save="save" />

    </div>
</template>

<script>
import TitleButton from './TitleButton.vue'

export default {
    name: "Page Title",
    components: {
        TitleButton,
    },
    props: {
        title: {
            type: String,
            required: true
        },
        buttonGroup: {
            type: String,
        },
        routeLink: {
            type: String,
        },
        buttonCancel:{
          type: String,
        },
        buttonDuplicate:{
          type: String,
        }
    },
    methods:{
      save(data){
          this.$emit("save", data);
      },
      duplicate(id){
        this.$emit("duplicate",id);
      },
      back(){
          this.$emit("back");
      }
    }
}
</script>