<template>
  <!-- Header -->
  <LookupHeader />
  <!-- Body -->
  <div class="relative pt-8 bg-gradient-to-br from-[#89f7fe] to-[#66a6ff] to-60% lg:to-80% xl:pt-0">
    <div class="mx-auto xl:flex xl:max-w-[1413px]">
      <div class="mb-10 mx-auto max-w-7xl px-12 relative z-20 xl:pt-24 xl:w-[49rem]">
        <div class="mx-auto max-w-2xl text-center">
          <h1 class="text-4xl font-medium text-white uppercase mb-2 md:text-5xl lg:mb-4 lg:text-[87px]">True <span
              class="text-persian-blue font-bold">Transparency</span></h1>
          <h2 class="text-2xl text-white mb-8 md:text-3xl lg:text-5xl">Truth in Labeling</h2>
          <p class="text-persian-blue mb-6 text-justify text-last-center lg:mb-12 lg:text-2xl">At LEAN&trade;, we believe
            that every customer should have access to real test results for every product they purchase or consume. We are
            setting a new standard in transparency, providing you the Truth in Labeling.</p>
          <p class="text-persian-blue text-justify text-last-center mb-8 lg:mb-16 lg:text-2xl">Please enter the UPC
            barcode number from the back of the package and the LOT number, typically located at the bottom of the
            package. Hit "Look Up Results' to see your product's batch test results.</p>
          <!-- <div class="mb-2 mx-auto lg:mb-5 lg:w-[458px]">
            <label for="upc" class="sr-only">UPC</label>
            <input id="upc"
              class="relative block w-full rounded-md border-1 border-[#334c9d] mx-auto py-1.5 text-persian-blue uppercase ring-1 ring-inset ring-gray-100 text-center placeholder:text-sm placeholder:font-bold placeholder:text-persian-blue focus:z-10 focus:ring-2 focus:ring-inset focus:ring-dark-blue-600 sm:text-sm sm:leading-6 lg:border-2 lg:py-5 lg:rounded-xl lg:text-2xl lg:leading-4 lg:placeholder:text-2xl lg:placeholder:leading-4"
              type="text" required="" placeholder="UPC" v-model="upc" />
            <small class="p-error">{{ errUpc }}</small>
          </div> -->

          <Combobox as="div" class="relative mb-2 mx-auto lg:mb-5 lg:w-[458px]" v-model="upc">
            <div class="relative">
              <ComboboxInput
                class="relative block w-full rounded-md border-1 border-[#334c9d] mx-auto py-1.5 text-persian-blue uppercase ring-1 ring-inset ring-gray-100 text-center placeholder:text-sm placeholder:font-bold placeholder:text-persian-blue focus:z-10 focus:ring-2 focus:ring-inset focus:ring-dark-blue-600 sm:text-sm sm:leading-6 lg:border-2 lg:py-5 lg:rounded-xl lg:text-2xl lg:leading-4 lg:placeholder:text-2xl lg:placeholder:leading-4 "
                type="text" required="" placeholder="UPC" @change="query = $event.target.value"
                :displayValue="(product) => product.upc" />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 z-10 focus:outline-none">
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                    clip-rule="evenodd" />
                </svg>
              </ComboboxButton>
            </div>
            <ComboboxOptions v-if="filteredProducts.length > 0"
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ComboboxOption v-for="product in filteredProducts" :key="product.upc" :value="product"
                v-slot="{ active, selected }">
                <li
                  :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-dark-blue-600 text-white' : 'text-gray-900']">
                  <div class="flex">
                    <span :class="[selected && 'font-semibold']">
                      {{ product.upc }}
                    </span>
                    <span
                      :class="['hidden ml-2 truncate text-gray-500 md:inline', active ? 'text-white' : 'text-gray-500']">
                      {{ product.product_line }}
                    </span>
                    <span :class="['ml-2 truncate text-gray-500', active ? 'text-white' : 'text-gray-500']">
                      {{ product.flavor }}
                    </span>
                  </div>

                  <span v-if="selected"
                    :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-dark-blue-600']">
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </li>
              </ComboboxOption>
            </ComboboxOptions>
            <small class="p-error block bg-red leading-loose mt-2 mb-4 rounded-md text-sm text-white w-full">{{
              errUpc }}</small>
          </Combobox>

          <div class="mb-2 mx-auto lg:mb-5 lg:w-[458px]">
            <label for="lot_id" class="sr-only">Lot No.</label>
            <input id="lot_id"
              class="relative block w-full rounded-md border-1 border-[#334c9d] mx-auto py-1.5 text-persian-blue uppercase ring-1 ring-inset ring-gray-100 text-center placeholder:text-sm placeholder:font-bold placeholder:text-persian-blue focus:z-10 focus:ring-2 focus:ring-inset focus:ring-dark-blue-600 sm:text-sm sm:leading-6 lg:border-2 lg:py-5 lg:rounded-xl lg:text-2xl lg:leading-4 lg:placeholder:text-2xl lg:placeholder:leading-4"
              type="text" required="" placeholder="Lot" v-model="lot_id" />
            <small class="p-error block bg-red leading-loose mt-2 mb-4 rounded-md text-sm text-white w-full">{{ errLot
            }}</small>
          </div>
          <button
            class="rounded-md w-full font-bold bg-persian-blue py-3 leading-4 text-sm text-white uppercase w-64 lg:py-5 lg:rounded-xl lg:text-2xl lg:w-[458px]"
            @click="getTestResults()">Look Up Results</button>
        </div>
      </div>
      <img class="mx-auto relative z-[15] xl:hidden" src="../../images/lookup/model-mobile.png"
        alt="LEAN Hydration Model" />
      <img class="mx-auto relative z-20 hidden xl:block" src="../../images/lookup/model-desktop.png"
        alt="LEAN Hydration Model" />
      <div
        class="repeating-logo absolute opacity-5 mix-blend-multiply bg-[#0d3b8e] bg-cover bg-repeat content-[''] inset-0 h-full w-full z-10">
      </div>
    </div>
  </div>
  <!-- Footer -->
  <LookupFooter />
</template>

<script>
import LookupHeader from '../components/LookupHeader'
import LookupFooter from '../components/LookupFooter'
import axios from 'axios'
import { ref, onMounted, computed } from 'vue'

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/vue'

export default {
  name: "TestResultLookup",
  components: {
    LookupHeader,
    LookupFooter,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions
  },
  setup() {
    const products = ref([])
    const query = ref('')
    const filteredProducts = computed(() =>
      query.value === ''
        ? products.value
        : products.value.filter((product) => {
          return product.upc.toLowerCase().includes(query.value.toLowerCase())
        })
    )
    function fetchProducts() {
      axios.get(`/api/lookup`).then(response => {
        const result = response.data;
        if (!result.success) {
          if (result.message) {
            alert(result.message);
          }
          return false;
        }
        products.value = result.data;
      }).catch(error => {
        console.error(error);
      });
    }

    onMounted(() => {
      fetchProducts();
    });
    return {
      query,
      filteredProducts,
      products
    }
  },
  data() {
    return {
      end_point: 'lookup',
      upc: ref({}),
      errUpc: '',
      errLot: '',
      lot_id: this.$route.query.lot_id || '',
    }
  },
  created() {

  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      return await this.$recaptcha('submit');
    },
    async getTestResults() {
      //validate
      let submit = true;
      this.errUpc = '';
      this.errLot = '';
      if (!this.upc) {
        submit = false;
        this.errUpc = 'UPC required';
      }
      if (!this.lot_id) {
        submit = false;
        this.errLot = 'Lot No required';
      }
      if (!submit) {
        return false;
      }
      const token = await this.recaptcha();
      const config = {
        headers: {
          'Captcha-Token': token
        }
      };
      this.$axios.get(`/api/${this.end_point}/${this.upc.upc}/${this.lot_id}`, config).then(response => {
        const result = response.data;
        if (!result.success) {
          if (result.message) {
            this.errLot = result.message;
          }
          return false;
        }
        this.$router.push({ name: 'TestResultPage', params: { upc: this.upc.upc, lot_id: this.lot_id, id: result.data.id } });
      }).catch(error => {
        console.error(error);
      });

    },
  }
}
</script>
