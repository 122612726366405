

export function redirectTimeout(){
    alert('Session timed out. Please log back in.');
    window.location.href = getAdminPath();
}

export function getAdminPath(){
    return '/admin';
}

export function getAnalysisTypes(){
    return ['Physical','Chemical','Microbial'];
}

export function getDataTypes(){
    return ['date','time','uom','weight','count','operator','test_method'];
}