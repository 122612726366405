<template>
    <div class="flex mt-0 ml-4">
        
        <!-- New -->
        <a v-if="buttonType === 'Create' || buttonType == 'Upload'"  href="#" class="bg-dark-gunmetal text-white border border-dark-gunmetal inline-flex items-center rounded-md px-4 py-2 shadow-sm" @click="this.$router.push({ path: `${admin_path+routeLink}`})">
            <img :src="this.createIcon" :class="['mr-3 flex-shrink-0 h-6 w-6']" viewBox="0 0 22 22" aria-hidden="true">
            {{ buttonType }} New
        </a>

        <!-- Save Button Group -->
        <button v-if="buttonCancel === 'Cancel'" type="button" class="relative inline-flex items-center rounded-md border border-black bg-black px-4 py-2 text-white shadow-sm mr-6" @click="back()">
          Cancel
        </button>
      <button v-if="buttonDuplicate === 'Duplicate'  && idEdit" type="button" class="relative inline-flex items-center rounded-md border border-black bg-black px-4 py-2 text-white shadow-sm mr-6" @click="duplicate(idEdit)">
        Duplicate
      </button>
        <button v-if="buttonType === 'Save'" type="button" class="relative inline-flex items-center rounded-l-md border border-dark-gunmetal bg-dark-gunmetal px-4 py-2 text-white shadow-sm" @click="save(2)">
            <img :src="this.saveIcon" class="mr-3 flex-shrink-0 h-5 w-5" viewBox="0 0 20 20" aria-hidden="true">
            Save
        </button>
        <Menu v-if="buttonType === 'Save'" as="div" class="relative -ml-px block">
            <MenuButton class="relative inline-flex items-center rounded-r-md border border-dark-gunmetal bg-white px-2 py-3 text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Open options</span>
                <img :src="this.chevronDownIcon" class="flex-shrink-0 h-4 w-4" viewBox="0 0 18 18" aria-hidden="true">
            </MenuButton>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-md bg-dark-gunmetal shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                    <MenuItem v-for="option in saveOptions" :key="option.name" v-slot="{ active }">
                    <a :href="option.href"
                       class="block px-4 py-2 text-white inline-flex items-center" @click="save(option.is_continue)">
                        <img :src="option.icon" class="mr-3 flex-shrink-0 h-4 w-4" viewBox="0 0 18 18" aria-hidden="true">
                        {{ option.name }}
                    </a>

                    </MenuItem>
                </div>
                </MenuItems>
            </transition>
        </Menu>

    </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import ChevronDownIcon from '../../images/button-icons/chevron-down-icon.svg'
import SaveIcon from '../../images/button-icons/save-icon.svg'
import SaveAndCloseIcon from '../../images/button-icons/save-and-close-icon.svg'
import SaveAndNewIcon from '../../images/button-icons/save-and-new-icon.svg'
import CreateNewIcon from '../../images/button-icons/create-new-icon.svg'

const admin_path = '/admin';

export default {
    name: "Title Buttons",
    props: {
        buttonType: {
            type: String,
        },
        routeLink: {
            type: String,
        },
        buttonCancel:{
          type: String,
        },
        buttonDuplicate:{
          type: String,
        }
    },
    components: {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems
    },
    data() {
        return {
            saveOptions: [
                { name: 'Save and Close', href: '#', icon: SaveAndCloseIcon, is_continue:false },
                { name: 'Save and New', href: '#', icon: SaveAndNewIcon, is_continue:true },
            ],
            admin_path:admin_path,
            idEdit:this.$route.params.id || false,
        }
    },
    created() {
        this.createIcon = CreateNewIcon
        this.chevronDownIcon = ChevronDownIcon
        this.saveIcon = SaveIcon
    },
    methods:{
        save(is_continue){
            this.$emit("save", is_continue);
        },
      duplicate(id){
        this.$emit("duplicate",id);
      },
        back(){
          this.$emit("back");
        }
    }
}
</script>